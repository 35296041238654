<template>
    <div class="moduleWrapper">
        <div class="contents validateContent">
            <div class="fixedWrapper">
                <toaster size="small" :showToasterModal="showToaster" icon="alert-fill" :firstHeadline="errorMessage" color="red" @modal-close-clicked="closeToaster" />
                <headline :text="!isManualInput ? 'quickCreator.validateContent.headline' : 'quickCreator.validateContent.manualHeadline'" class="mds-heading-three" :size-class="headlineSizeClass" />
                <preamble :text="!isManualInput ? 'quickCreator.validateContent.preamble' : 'quickCreator.validateContent.manualPreamble'" class="preamble" :size-class="preambleSizeClass" />
            </div>
            <div class="fixedWrapper wide">
                <div class="fullWidthSidebar">
                    <sidenav
                        v-if="hasLoadedAiContent"
                        :sidenavTitle="sidenavTitle"
                        :routes="routes"
                        :active-key="activeKey"
                        @updateActiveKey="updateActiveKey"
                        routesColor="blue"
                        tagColor="blue" />
                </div>
                <div class="validateContentWrapper">
                    <div v-if="!hasLoadedAiContent" class="loadingWrap">
                        <loader size="large" color="blue" isLoading />
                        <div v-if="!isManualInput" class="loadingMessageContainer">
                            <transition name="slide-fade" mode="out-in">
                                <p class="loading-text" :key="currentMessage">{{ $t(currentMessage) }}</p>
                            </transition>
                        </div>
                    </div>
                
                    <template v-if="hasLoadedAiContent">
                        <div v-for="(column, index) in aiContentRestructured[activeKey]" :key="index" :class="{ contentWithImage: isFieldWithImage(column) }">
                            <div v-for="(content, key) in column" :key="key">
                                <component v-if="!hasImages(content)" :is="shouldUseTextArea(content, key) ? 'input-text-area' : 'input-text'"
                                    :index="key"
                                    :class="{ disabled: routes[activeKey].isConfirmed || fetchingNewAiContent, isManualInput }"
                                    class="mds-input-text validation-area" :label="prettifyName(key)"
                                    :value="content"
                                    :icon="canEdit ? 'check' : 'refresh'"
                                    :disabled="routes[activeKey].isConfirmed || fetchingNewAiContent"
                                    @icon-click="fetchContentAi(content, key)"
                                    @change="updateContentAi" />

                                <template v-if="hasImages(content)">
                                    <template v-if="isImage(content)">
                                        <div class="single-image">
                                            <file
                                                v-if="!hasUploadedImage(key)"
                                                :class="{ dropImgDisable: routes[activeKey].isConfirmed }"
                                                dropzoneMessage="quickCreator.validateContent.fileField.dropMessage"
                                                :index="key"
                                                :required="false"
                                                :urlUpload="false"
                                                :options="imageOptions.files"
                                                fileApiEndpoint="false"
                                                siteUrl="false"
                                                :event-bus="false"
                                                @change="uploadComplete"
                                            />
                                            <div v-if="hasUploadedImage(key)">
                                                <div class="image-container">
                                                    <span v-if="!routes[activeKey].isConfirmed" class="red-circle" @click="deleteImage(key)">
                                                        <span class="white-cross"></span>
                                                    </span>
                                                    <img :src="imagesUploaded[key][0].value" alt="Uploaded Image" width="120" height="120" />
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                    <template v-if="isObjectWithImages(content)">
                                        <div class="mvc-field mvc-file label-above">
                                            <label class="mono-size-tiny"> {{ imageCountText(content) }}</label>
                                        </div>
                                        <div class="gallery">
                                            <div v-for="(item, index) in content" :key="index" class="gallery-image">
                                                <file
                                                    v-if="!hasUploadedImage(key, index)"
                                                    :class="{ dropImgDisable: routes[activeKey].isConfirmed }"
                                                    dropzoneMessage="quickCreator.validateContent.fileField.dropMessage"
                                                    :index="key + `idx:` + index"
                                                    :required="false"
                                                    :addFromFileLibrary="false"
                                                    :urlUpload="false"
                                                    :options="imageOptions.files"
                                                    :event-bus="false"
                                                    fileApiEndpoint="false"
                                                    siteUrl="false"
                                                    @change="uploadComplete"
                                                />
                                                <div v-else>
                                                    <div class="image-container">
                                                        <span v-if="!routes[activeKey].isConfirmed" class="red-circle" @click="deleteImage(key, index)">
                                                            <span class="white-cross"></span>
                                                        </span>
                                                        <img :src="imagesUploaded[key][index][0].value" alt="Uploaded Image" width="120" height="120" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                </template>
                            </div>
                        </div>
                    </template>

                    <div v-if="hasLoadedAiContent" class="buttonsContainer">
                        <mv-button
                            class="borderless"
                            :text="canEdit ? 'quickCreator.validateContent.buttons.editText' : 'quickCreator.validateContent.buttons.useText'"
                            @click="buttonClick" />
                    </div>
                </div>
                <div class="emptyDiv"></div>
                <div v-if="(noCreditsFound || isAIAPIDown) && errorMessageModal.show" class="modal-overlay">
                    <mv-modal
                        :title="isAIAPIDown ? errorMessageModal.title : noCreditsFoundModal.title"
                        :description="isAIAPIDown ? errorMessageModal.description : noCreditsFoundModal.description"
                        size="medium"
                        :firstButton="false"
                        :secondButton="false">
                            <div slot="mv-modal-content" class="greyWrapper">
                                <div class="wrapper">
                                    <span class="text">{{ isAIAPIDown ? $t(errorMessageModal.descriptionLong) : $t(noCreditsFoundModal.descriptionLong) }}</span>
                                    <div class="buttonWrapper">
                                        <mv-button class="borderless buttonOne" :text="errorMessageModal.button1" size="large" @click="modalButtonClick(1)" />
                                        <mv-button class="borderless" :text="errorMessageModal.button2" size="large" @click="modalButtonClick(2)" />
                                    </div>
                                </div>
                            </div>
                    </mv-modal>
                </div>
                <mv-icon-sprite />
            </div>
        </div>
    </div>
</template>

<script>
import QCModuleMixin from '../mixins/QCModuleMixin';
import { Headline, InputTextArea, InputText, Preamble, MvIconSprite, MvButton, Loader, File, Toaster, MvModal } from "@monosolutions/vue-components";
import Sidenav from '../components/Sidenav.vue';
import { mapGetters } from 'vuex';
import axios from 'axios';
import FileExtensions from '@/utils/fileExtensions';
const fileExtensions = new FileExtensions();

export default {
    name: 'qc-validate-content',
    components: { Headline, Preamble, InputTextArea, InputText, MvIconSprite, Sidenav, MvButton, Loader, File, Toaster, MvModal },
    mixins: [ QCModuleMixin ],
    data() {
        return {
            activeKey: '',
            currentRouteKey: null,
            aiContent: {},
            fetchingNewAiContent: false,
            routes: {},
            sidenavTitle: 'quickCreator.validateContent.menuTitle',
            currentMessage: 'quickCreator.validateContent.loadingMessage.message1',
            messages: [
                'quickCreator.validateContent.loadingMessage.message2',
                'quickCreator.validateContent.loadingMessage.message3',
                'quickCreator.validateContent.loadingMessage.message4',
                'quickCreator.validateContent.loadingMessage.message5',
            ],
            imageOptions: {
                files: {
                    createImageThumbnails: true,
                    maxFiles: 1,
                    maxFilesize: 20,
                    acceptedFiles: fileExtensions.getAsString()
                }
            },
            showDropzone: true,
            aiContentRestructured: {},
            imagesUploaded: {},
            imagesData: {},
            groupingIndexes: {},
            errorMessage: '',
            showToaster: false,
            errorMessageModal: {
                show: true,
                title: 'quickCreator.moreInformationNew.errorMessageModal.title',
                description: 'quickCreator.moreInformationNew.errorMessageModal.description',
                descriptionLong: 'quickCreator.moreInformationNew.errorMessageModal.descriptionLong',
                button1: 'quickCreator.moreInformationNew.errorMessageModal.button1',
                button2: 'quickCreator.moreInformationNew.errorMessageModal.button2'
            },
            noCreditsFoundModal: {
                title: 'quickCreator.moreInformationNew.noCreditsFoundModal.title',
                description: 'quickCreator.moreInformationNew.noCreditsFoundModal.description',
                descriptionLong: 'quickCreator.moreInformationNew.noCreditsFoundModal.descriptionLong'
            }
        };
    },
    computed: {
        ...mapGetters({
            sessionId: 'sessionId',
            aiData: 'AIData',
            isManualInput: 'isManualInput',
            storedImages: 'storedImages',
            noCreditsFound: 'noCreditsFound',
            isAIAPIDown: 'isAIAPIDown'
        }),
        isMobile() {
            return this.$mq === 'mobile';
        },
        headlineSizeClass() {
            return this.isMobile ? 'mono-size-big' : 'mono-size-huge';
        },
        preambleSizeClass() {
            return this.isMobile ? 'mono-size-tiny' : 'mono-size-normal';
        },
        canConfirm() {
            return !this.routes[this.activeKey].isConfirmed;
        },
        canEdit() {
            return this.routes[this.activeKey].isConfirmed;
        },
        isDisabled() {
            return this.routes[this.activeKey].isConfirmed && !this.fetchingNewAiContent;
        },
        quickCreatorBaseUrl() {
            return process.env.VUE_APP_QC_BASE_URL;
        },
        hasLoadedAiContent() {
            return this.aiData ? Object.keys(this.aiData).length > 0 : null;
        },
        menuKeys() {
            return Object.keys(this.routes);
        },
    },
    watch: {
        hasLoadedAiContent(value) {
            if (value && this.aiData) {
                this.aiContent = this.aiData;
                this.assignGroupIndexes();
                this.routes = this.buildRoutes(this.aiContent);
                this.aiContentRestructured = this.regroupAIContent(this.aiContent);
            }
        }
    },
    mounted() {
        this.slideMessages();
        this.imagesUploaded = this.storedImages;
        if (this.hasLoadedAiContent && this.isManualInput) {
            this.aiContent = this.aiData;
            this.assignGroupIndexes();
            this.routes = this.buildRoutes(this.aiContent);
            this.aiContentRestructured = this.regroupAIContent(this.aiContent);
        }
    },
    destroyed() {
        this.$store.dispatch('setAIData', this.aiContent);
    },
    methods: {
        buildRoutes(aiContent) {
            const routes = {};
    
            for (const key in this.menuRoutes) {
                if (Object.prototype.hasOwnProperty.call(aiContent, key)) {
                    routes[key] = this.menuRoutes[key];
                    if (!this.activeKey) {
                        this.activeKey = key;
                    }
                }
            }
            
            return routes;
        },

        regroupAIContent(aiContent) {
            const groupedAiContent = {};
            for (const category in aiContent) {
                groupedAiContent[category] = {};

                for (const key in aiContent[category]) {
                    const [, index, ] = key.split('_');
                    
                    if (!groupedAiContent[category][index]) {
                        groupedAiContent[category][index] = {};
                    }

                    groupedAiContent[category][index] = {
                        ...groupedAiContent[category][index],
                        [key]: aiContent[category][key]
                    };
                }
            }

            return groupedAiContent;
        },
        
        hasImages(content) {
            if (Array.isArray(content)) {
                return content.some(item => this.isImage(item));
            } else {
                return this.isImage(content);
            }
        },

        isImage(content) {
            return typeof content === 'string' && content === "image";
        },

        isObjectWithImages(content) {
            if (typeof content !== 'object') return false;
            return Object.values(content).some(value => this.hasImages(value));
        },

        isFieldWithImage(content) {
            if (!Object.values(content).some(value => typeof value === 'string' && value.toLowerCase().includes("headline"))) {
                return false;
            }
            for (const key in content) {
                if (typeof content[key] === 'object') {
                    if (this.isFieldWithImage(content[key])) {
                        return true;
                    }
                } else if (typeof content[key] === 'string' && content[key] === "image") {
                    return true;
                }
            }
        },

        shouldUseTextArea(content, key) {
            const regex = new RegExp("Paragraph", "g");
            const matches = key.match(regex);
            return content.length > 70 || matches?.length > 0;
        },

        prettifyName(name) {
            const removeType = name.replace(this.activeKey, '');
            const stringName = removeType.match(/[a-zA-Z]*/)[0];
            const index = this.groupingIndexes[name];

            return `${stringName} ${index}`;
        },

        updateContentAi(payload) {
            this.$set(this.aiContent[this.activeKey], payload.index, payload.data.value);
            this.aiContentRestructured = this.regroupAIContent(this.aiContent);
        },

        async fetchContentAi(content, key) {
            if (this.fetchingNewAiContent) return;

            this.fetchingNewAiContent = true;

            try {
                const newContent = await axios.post(`${this.quickCreatorBaseUrl}/regenerate-field-content`, {
                    text: content,
                    fieldName: key,
                    tabCategory: this.activeKey
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'x-mono-sid': this.sessionId
                    }
                });

                this.$set(this.aiContent[this.activeKey], key, newContent.data.body.text);
                this.aiContentRestructured = this.regroupAIContent(this.aiContent);
            } catch (error) {
                if (error.response.status === 429) {
                    this.showToaster = true;
                    this.errorMessage = 'quickCreator.validateContent.noCredistLeft';
                    this.fetchingNewAiContent = false;
                }
                console.log(error);
            }

            this.fetchingNewAiContent = false;
        },

        checkConfirmationStatus() {
            if (Object.values(this.routes).every(route => route.isConfirmed)) {
                this.setStepReady(true);
                this.setStepDone(true);
            } else {
                this.setStepReady(false);
                this.setStepDone(false);
            }
        },

        buttonClick() {
            if (this.routes[this.activeKey].isConfirmed) {
                this.routes[this.activeKey].isConfirmed = false;
                this.checkConfirmationStatus();
                return;
            }
            this.routes[this.activeKey].isConfirmed = true;
            this.moveToNextMenuItem();
            this.checkConfirmationStatus();
        },

        updateActiveKey(payload) {
            this.activeKey = payload;
        },

        moveToNextMenuItem() {
            const currentIndex = this.menuKeys.indexOf(this.activeKey);
            const nextIndex = (currentIndex + 1) % this.menuKeys.length;
            this.activeKey = this.menuKeys[nextIndex];
        },

        slideMessages() {
            let index = 0;
            setInterval(() => {
                this.currentMessage = this.messages[index];
                index = (index + 1) % this.messages.length;
            }, 4000);
        },

        uploadComplete(file) {
            const newData = {
                value: file.data.value
            };

            let fileIndex = file.index;
            let indexImg = false;
            const regex = /(.*?_\d+)(idx:\d+)/;
            const matches = fileIndex.match(regex);

            if (!file.data.value) {
                this.showToaster = true;
                this.errorMessage = 'quickCreator.loadingSite.imagesUploadError';
                this.rerenderFields();
                return;
            }

            if (matches) {
                fileIndex = matches[1];
                indexImg = matches[2].split(":")[1];
                if (!this.imagesUploaded[fileIndex]) {
                    this.$set(this.imagesUploaded, fileIndex, {});
                }
                this.$set(this.imagesUploaded[fileIndex], indexImg, [newData]);
            } else {
                this.$set(this.imagesUploaded, fileIndex, [newData]);
            }
            this.$store.dispatch('setStoredImages',  this.imagesUploaded);
            this.createImageData(fileIndex, indexImg, newData.value || '');
        },

        deleteImage(key, index = 0) {
            if (this.imagesUploaded[key] && this.imagesUploaded[key][index] !== undefined) {
                if (this.imagesUploaded[key][index].length > 0) {
                    this.imagesUploaded[key][index][0].value = null;
                }
                this.imagesUploaded[key][index].value = null;
            }
            this.$store.dispatch('setStoredImages',  this.imagesUploaded);
            this.createImageData(key, index, null);
        },

        hasUploadedImage(key, index = 0) {
            if (this.imagesUploaded[key] && this.imagesUploaded[key][index] !== undefined) {
                if (!Array.isArray(this.imagesUploaded[key][index]) && this.imagesUploaded[key][index].value?.length) {
                    return true;
                }
                if (this.imagesUploaded[key][index].length === 1 && this.imagesUploaded[key][index][0].value?.length) {
                    return true;
                }
            }
            return false;
        },

        createImageData(fileIndex, nestedIndex, value) {
            if (!this.imagesData[this.activeKey]) {
                this.$set(this.imagesData, this.activeKey, {});
            }
            if (!Array.isArray(this.imagesData[this.activeKey][fileIndex])) {
                this.$set(this.imagesData[this.activeKey], fileIndex, [value]);
            }
            this.imagesData[this.activeKey][fileIndex][nestedIndex] = value;
            this.$store.dispatch('setImagesToSend',  this.imagesData);
        },

        imageCountText(content) {
            return this.$t('quickCreator.validateContent.imageCount').replace("{{X}}", content.length);
        },

        closeToaster() {
            this.showToaster = false;
        },

        rerenderFields() {
            this.aiContentRestructured = {};
            this.$nextTick(() => {
                this.aiContentRestructured = this.regroupAIContent(this.aiContent);
            });
        },

        async modalButtonClick(decision) {
            if (decision === 1) {
                this.$store.dispatch('setIsManualInput', true);
                await this.loadTemplateContent();
                this.errorMessageModal.show = false;
            }
            else if (decision === 2) {
                this.$store.dispatch('setIsAIFlow', false);
                document.body.classList.remove('disable-scroll');
                this.$router.push('/loading').catch(()=>{});
            }
        },

        assignGroupIndexes() {
            for (const [menuOption, fields] of Object.entries(this.aiContent)) {
                
                const fieldsList = Object.keys(fields).reduce((acc, fieldName) => {
                    const fieldType = fieldName.replace(menuOption, "").split("_")[0];
                    acc[fieldType] = fieldType in acc ? [...acc[fieldType], fieldName] : [fieldName];
                    return acc;
                }, {});

                for (const fields of Object.values(fieldsList)) {
                    for (const [index, field] of fields.entries()) {
                        this.groupingIndexes[field] = index+1;
                    }
                }
            }
        }
    }
};
</script>
