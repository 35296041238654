<template>
    <header class="header">
        <div class="logo">
            <a href="#" @click="logout"><img src="../assets/logo_svg.svg" class="mono-site-logo" width="92" height="32" /></a>
        </div>
        <div v-if="showProgressBar" class="progressBar" :style="progress" />
    </header>
</template>

<script>
import { $ss } from "@/plugins/localstorage";
import { mapGetters } from 'vuex';
import QCModuleMixin from '../mixins/QCModuleMixin';
import QCNavigationMixin from '../mixins/QCNavigationMixin';

export default {
    name: 'qc-header',
    mixins: [ QCModuleMixin, QCNavigationMixin ],
    data() {
        return {
            showProgressBar: true
        };
    },
    computed: {
        ...mapGetters({
            currentStepIndex: 'currentStepIndex',
            isAIFlow: 'isAIFlow'
        }),
        currentModuleNumber() {
            if (this.$route.name === 'loading' || this.$route.name === 'preview') {
                return 100;
            }
            if (this.$route.name === 'login') {
                return 0;
            }
            if (!this.isAIFlow && this.currentStepIndex > 2) {
                return this.currentStepIndex + 2;
            }
            return this.currentStepIndex + 1;
        },
        progress() {
            return {
                width: this.currentModuleNumber <= 0 ? '0%' : `${((this.currentModuleNumber) / (this.allModules.length + 1)) * 100}%`
            };
        }
    },
    methods: {
        logout() {
            $ss.set('loginToken', '');
            $ss.set('stepsDone', '');
            this.clearInputs();
            this.$store.dispatch('setCurrentStepIndex', 0);
            this.$router.push('/login').catch(() => { });
        }
    }
};
</script>
