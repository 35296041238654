<template>
    <div class="moduleWrapper">
        <div class="sideimage">
            <img :src="'../images/' + sidebarMoreInfoImg" class="illustration">
        </div>
        <div class="contents moreInformation">
            <div class="fixedWrapper">
                <headline text="quickCreator.moreInformation.headline" class="mds-heading-three" :size-class="headlineSizeClass" />
                <preamble text="quickCreator.moreInformation.preamble" class="preamble" :size-class="preambleSizeClass" />
            </div>
            <div class="fixedWrapper inputs">
                <fields-more-information />
            </div>
            <mv-icon-sprite />
        </div>
    </div>
</template>

<script>
import QCModuleMixin from '../mixins/QCModuleMixin';
import FieldsMoreInformation from '../components/FieldsMoreInformation.vue';
import { Headline, Preamble, MvIconSprite } from "@monosolutions/vue-components";
import { mapGetters } from 'vuex';

export default {
    name: 'qc-more-information',
    components: { Headline, Preamble, MvIconSprite, FieldsMoreInformation },
    mixins: [ QCModuleMixin ],
    data() {
        return {
            sidebarMoreInfoImg: '1x-Magicianshat.png'
        };
    },
    computed: {
        ...mapGetters({
            globalData: 'globalData',
            defaultLanguage: 'defaultLanguage'
        }),
        isMobile() {
            return this.$mq === 'mobile';
        },
        headlineSizeClass() {
            return this.isMobile ? 'mono-size-big' : 'mono-size-huge';
        },
        preambleSizeClass() {
            return this.isMobile ? 'mono-size-tiny' : 'mono-size-normal';
        }
    }
};
</script>
