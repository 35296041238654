<template>
    <div class="moduleWrapper">
        <div class="sideimage">
            <img :src="'../images/' + sidebarAccInfoImg" class="illustration">
        </div>
        <div class="contents accountInformation">
            <div class="fixedWrapper">
                <headline text="quickCreator.accountInformation.headline" class="mds-heading-three" :size-class="headlineSizeClass" />
                <preamble text="quickCreator.accountInformation.preamble" class="preamble" :size-class="preambleSizeClass" />
            </div>
            <div class="fixedWrapper">
                <div class="details-form">
                    <input-text
                        name="first_name"
                        class="mds-input-text"
                        label="quickCreator.accountInformation.form.first_name"
                        index="first_name"
                        :placeholderText="this.$t('quickCreator.accountInformation.placeholder.firstname')"
                        :value="this.accountObject.first_name"
                        :valid="formErrors.first_name === ''"
                        :error-message="formErrors.first_name"
                        @change="updateAccountObject" />
                    <input-text
                        name="last_name"
                        class="mds-input-text"
                        label="quickCreator.accountInformation.form.last_name"
                        index="last_name"
                        :placeholderText="this.$t('quickCreator.accountInformation.placeholder.lastname')"
                        :value="this.accountObject.last_name"
                        :valid="formErrors.last_name === ''"
                        :error-message="formErrors.last_name"
                        @change="updateAccountObject" />
                    <input-text
                        name="account_email"
                        class="mds-input-text"
                        label="quickCreator.accountInformation.form.email"
                        index="account_email"
                        :placeholderText="this.$t('quickCreator.accountInformation.placeholder.email')"
                        :value="this.accountObject.account_email"
                        :valid="formErrors.account_email === ''"
                        :error-message="formErrors.account_email"
                        @change="updateAccountObject" />
                    <input-phone-field
                        name="account_phone"
                        class="mds-input-text"
                        :defaultCountry="currentCountryCode"
                        label="quickCreator.accountInformation.form.phone"
                        index="account_phone"
                        :placeholder="this.$t('quickCreator.accountInformation.placeholder.phone')"
                        :value="this.accountObject.account_phone"
                        :valid="formErrors.account_phone === ''"
                        :error-message="formErrors.account_phone"
                        @changePhoneNumber="updateAccountObjectPhone" />
                    <input-text
                        name="customerId"
                        class="mds-input-text"
                        label="quickCreator.accountInformation.form.customerId"
                        index="customerId"
                        :placeholderText="this.$t('quickCreator.accountInformation.placeholder.customerid')"
                        :value="this.accountObject.customerId"
                        :valid="formErrors.customerId === ''"
                        :error-message="formErrors.customerId"
                        @change="updateAccountObject" />
                </div>
            </div>
            <mv-icon-sprite />
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import QCModuleMixin from '../mixins/QCModuleMixin';
import { Headline, Preamble, InputText, MvIconSprite, InputPhoneField } from "@monosolutions/vue-components";
import Joi from 'joi';

export default {
    name: 'qc-account-information',
    components: { Headline, Preamble, InputText, MvIconSprite, InputPhoneField },
    mixins: [ QCModuleMixin ],
    data() {
        return {
            accountObject: {
                first_name: "",
                last_name: "",
                account_email: "",
                account_phone: "",
                customerId: ""
            },
            formErrors: {
                first_name: "",
                last_name: "",
                account_email: "",
                account_phone: "",
                customerId: ""
            },
            sidebarAccInfoImg: '1x-Plantbeingwatered.png'
        };
    },
    computed: {
        ...mapGetters({
            globalData: 'globalData',
            accountData: 'accountData',
            accountPhoneCountryCode: 'accountPhoneCountryCode'
        }),
        currentCountryCode() {
            return this.accountPhoneCountryCode;
        },
        isMobile() {
            return this.$mq === 'mobile';
        },
        headlineSizeClass() {
            return this.isMobile ? 'mono-size-big' : 'mono-size-huge';
        },
        preambleSizeClass() {
            return this.isMobile ? 'mono-size-tiny' : 'mono-size-normal';
        },
        validationSchema() {
            return {
                first_name: Joi.string().allow(''),
                last_name: Joi.string().allow(''),
                account_email: Joi.string().email({ minDomainSegments: 2, tlds: false }).allow(''),
                account_phone: Joi.string().regex(/^[+]?[(]?\d{1,4}[)]?[-\s./0-9]*$/).allow(''),
                customerId: Joi.string().allow('')
            };
        }
    },
    mounted() {
        this.accountObject = this.accountData;
        const anyStepFilledUp = Object.keys(this.accountObject).filter(element => this.accountObject[element]);
        if (anyStepFilledUp.length) {
            this.setStepReady(true);
        }
        this.setStepDone(true);
    },
    methods: {
        updateAccountObject(payload) {
            this.accountObject[payload.index] = payload.data.value;
            this.setStepReady(false);
            this.saveAccountData(payload);
        },
        updateAccountObjectPhone(payload) {
            this.$nextTick(() => {
                if (document.getElementsByClassName('vti__flag')[0] && document.getElementsByClassName('vti__flag')[0].classList.length > 1) {
                this.$store.dispatch('setAccountPhoneCountryCode', document.getElementsByClassName('vti__flag')[0]?.classList[1]);            }
            });
            if (payload.data.value !== "") {
                this.accountObject[payload.index] = payload.data.value;
                this.setStepReady(false);
                this.saveAccountData(payload);
            }
        },
        saveAccountData(payload) {
            this.setStepReady(false);
            let { value } = payload.data;
            this.checkFields(payload, value);
        },
        checkFields(payload, value) {
            const checkField = this.validationSchema[payload.index].validate(value);
            let errorMap;

            if (checkField.error) {
                errorMap = {
                    "string.email": this.$t("quickCreator.gmbDetails.errors.email.empty"),
                    "string.pattern.base": this.$t("quickCreator.accountInformation.errors.string.pattern")
                };
            }

            this.formErrors[payload.index] = checkField.error ? errorMap[checkField.error.details[0].type] : "";
            this.checkAllFields();
        },
        checkAllFields() {
            const checkAllFields = Joi.object().keys(this.validationSchema).validate(this.accountObject);
            
            if (checkAllFields.error) {
                this.setStepReady(false);
            } else {
                this.$store.dispatch('setAccountData', this.accountObject);
                this.setStepReady(true);
                this.setStepDone(true);
            }
        }
    }
};
</script>
