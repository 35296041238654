import Vue from 'vue';
import VueI18n from 'vue-i18n';
import axios from 'axios';
import { merge } from 'lodash';

Vue.use(VueI18n);

// eslint-disable-next-line no-unused-vars
function loadLocaleMessages () {
  const locales = require.context('../locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages = {};
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });

  return messages;
}

Vue.config.productionTip = false

const i18n = new VueI18n({
  locale: 'da',
  fallbackLocale: 'en',
  silentTranslationWarn: true,
  messages: {}
})

export default i18n;

const loadedLanguages = [];

function setI18nLanguage(lang) {
    i18n.locale = lang;
    return lang;
}

export function loadLanguage(lang, reseller = '', setLanguage = true) {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async(resolve, reject) => {
      if (!lang) {
          reject('You need to provide a language');
          return;
      }
      if (!loadedLanguages.includes(lang)) {
          let defaultLang = {};
          let resellerLang = {};

          //Getting default locale file
          try {
              defaultLang = (await (axios.get(`${process.env.VUE_APP_TRANSLATIONS_URL}/quick-creator/generic/${lang}.json`))).data;
          // eslint-disable-next-line no-empty
          } catch (e) {}

          //Getting reseller overwrite
          if (reseller?.length !== 0) {
            try {
              resellerLang = (await axios.get(`${process.env.VUE_APP_TRANSLATIONS_URL}/quick-creator/${reseller}/${lang}.json`)).data;
              // eslint-disable-next-line no-empty
            } catch (e) {}
          }

          i18n.setLocaleMessage(lang, merge(defaultLang, resellerLang));
          loadedLanguages.push(lang);
          resolve(setLanguage ? setI18nLanguage(lang) : false);
      } else {
          resolve(setLanguage ? setI18nLanguage(lang) : false);
      }
  });
}