<template>
    <div class="contents oldTemplateSelection">
        <div class="fixedWrapper">
            <headline text="quickCreator.templateSelection.headline" class="mds-heading-three" :size-class="headlineSizeClass" />
            <preamble text="quickCreator.templateSelection.preamble" class="preamble" :size-class="preambleSizeClass" />
        </div>
        <div class="themesView">
            <div class="themesContent" :class="{ loading: loadingTemplates }">
                <div class="filterWrapper">
                    <template-filter :tag-templates="templates" :has-toggle="isMobile" title="quickCreator.templateSelection.filterTitle" @filterUpdated="setFilterData" />
                </div>
                <div class="templateChooserWrapper">
                    <div v-if="loading" class="loadingWrap">
                        <div class="loading" />
                    </div>
                    <template-chooser 
                        :templates="filteredTemplates"
                        :selected-template-id="selectedTemplateId"
                        @templatePreview="openPreview"
                        @templateSelect="applyTemplate" />
                </div>
                <preview-overlay :show-combined-viewports="false" :url="previewUrl" name="previewOverlay" @applyTemplate="applyTemplate" />
            </div>
        </div>
        <mv-icon-sprite />
    </div>
</template>

<script>
import { Headline, Preamble, MvIconSprite} from "@monosolutions/vue-components";
import { mapGetters } from 'vuex';
import QCModuleMixin from '../mixins/QCModuleMixin';
import TemplateSelectMixin from '../mixins/TemplateSelectMixin';
import TemplateFilter from '../components/TemplateFilter.vue';
import TemplateChooser from '../components/TemplateChooser.vue';
import PreviewOverlay from '../components/PreviewOverlay.vue';

export default {
    name: 'qc-template-selection',
    components: { Headline, Preamble, TemplateFilter, TemplateChooser, PreviewOverlay, MvIconSprite },
    mixins: [ TemplateSelectMixin, QCModuleMixin ],
    props: {
        thumbPath: {
            type: String,
            'default': 'assets/themes/'
        }
    },
    data() {
        return {
            loading: false,
            selectedTemplateId: 0
        }
    },
    mounted() {
        this.selectedTemplateId = Number(this.templateId);
    },
    computed: {
        ...mapGetters({
            templateId: 'templateId',
            isAIFlow: 'isAIFlow'
        }),
        isMobile() {
            return this.$mq === 'mobile';
        },
        headlineSizeClass() {
            return this.isMobile ? 'mono-size-big' : 'mono-size-huge';
        },
        preambleSizeClass() {
            return this.isMobile ? 'mono-size-tiny' : 'mono-size-normal';
        },
    },
    methods: {
        async applyTemplate(template = this.currentTemplate) {
            this.loading = true;
            this.selectedTemplateId = template.id;
            this.$store.dispatch('setTemplateId', this.selectedTemplateId);
            try {
                this.loading = false;
                this.$modal.hide('previewOverlay');

                this.setStepReady(true);
                this.setStepDone(true);
            } catch (error) {
                this.setStepDone(false);
            }
        },
        openPreview(template) {
            this.previewTemplate(template);
        }
    }
};
</script>
