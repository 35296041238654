<template>
    <modal :name="name" :click-to-close="false" :adaptive="true" class="preview-overlay" @closed="close">
        <div class="overlay-header" @wheel.stop>
            <div v-if="$mq === 'desktop'" class="viewportsSwitchContainer">
                <div v-if="showCombinedViewports" :class="{'active': activeViewport === 'all'}" class="allViewports" @click="setVisibleViewport('all')">
                    <i class="mf-desktop" />
                    <i class="mf-tablet" />
                    <i class="mf-mobile" />
                </div>
                <i :class="{'active': activeViewport === 'desktop'}" class="mf-desktop" @click="setVisibleViewport('desktop')" />
                <i :class="{'active': activeViewport === 'tablet'}" class="mf-tablet" @click="setVisibleViewport('tablet')" />
                <i :class="{'active': activeViewport === 'mobile'}" class="mf-mobile" @click="setVisibleViewport('mobile')" />
            </div>
            <div class="buttonsContainer">
                <mv-button class="cancel" text="general.cancel" color="white" @click="close" />
                <mv-button class="apply" text="general.select" color="blue" @click="applyTemplate" />
            </div>
        </div>
        <div class="overlay-content" @wheel.stop>
            <div :class="[{'singleViewport': !allViewportsVisible}, activeViewport+'Active']" class="viewportsPreview">
                <div v-show="visibleViewports.desktop.visible" class="column desktopFrame">
                    <iframe :src="url" />
                </div>
                <div v-show="visibleViewports.tablet.visible" class="column tabletFrame">
                    <iframe :src="url" />
                </div>
                <div v-show="visibleViewports.mobile.visible" class="column mobileFrame">
                    <iframe :src="url" />
                </div>
            </div>
            <slot />
        </div>
    </modal>
</template>

<script>
import { MvButton } from "@monosolutions/vue-components";
import { every } from 'lodash';

export default {
    name: 'preview-overlay',
    components: { MvButton },
    props: {
        name: {
            type: String,
            'default': ''
        },
        url: {
            type: String,
            'default': () => {
                return '';
            }
        },
        showCombinedViewports: {
            type: Boolean,
            'default': true
        }
    },
    data() {
        return {
            visibleViewports: {
                desktop: { visible: true },
                tablet: { visible: this.showCombinedViewports },
                mobile: { visible: this.showCombinedViewports }
            },
            activeViewport: this.showCombinedViewports ? 'all' : 'desktop'
        };
    },
    computed: {
        allViewportsVisible() {
            return every(this.visibleViewports, 'visible');
        }
    },
    watch: {
        $mq(newVal) {
            if (newVal !== 'desktop') {
                this.setVisibleViewport(newVal);
            } else {
                this.setVisibleViewport('desktop');
            }
            this.switchHeaderAndContent();
        }
    },
    mounted() {
        if (this.$mq !== 'desktop') {
            this.setVisibleViewport(this.$mq);
        }
    },
    methods: {
        setVisibleViewport(vp) {
            if (vp === 'all') {
                for (const v in this.visibleViewports) {
                    this.visibleViewports[v].visible = true;
                }
                this.activeViewport = 'all';
            } else {
                for (const v in this.visibleViewports) {
                    this.visibleViewports[v].visible = false;
                }

                this.visibleViewports[vp].visible = true;
                this.activeViewport = vp;
            }
        },
        close() {
            this.$modal.hide(this.name);
            this.setVisibleViewport(this.showCombinedViewports ? 'all' : 'desktop');
        },
        applyTemplate() {
            this.$emit('applyTemplate');
            this.close();
        },
        switchHeaderAndContent() {
            const header = document.getElementsByClassName('overlay-header');
            const content = document.getElementsByClassName('overlay-content');
            if (header.length > 0 && content.length > 0) {
                if (this.$mq === 'mobile') {
                    content[0].after(header[0]);
                } else {
                    header[0].after(content[0]);
                }
            }
        }
    }
};
</script>