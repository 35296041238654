<template>
    <div class="mainWrapper fullWidthContent">
        <div class="moduleWrapper">
            <div class="contents websitePreview">
                <div class="fixedWrapper wide">
                    <headline text="quickCreator.websitePreview.headline" class="mds-heading-three" :size-class="headlineSizeClass" />
                    <preamble text="quickCreator.websitePreview.preamble" class="preamble" :size-class="preambleSizeClass" />

                    <div class="subtext">
                        <div class="circle">
                            <mv-icon type="check" :size="32" />
                        </div>
                        <span> {{ $t('quickCreator.websitePreview.previewLink') }} </span>
                        <a :href="previewLink" target="_blank"> {{ previewLink }} </a>
                    </div>

                    <div class="editorWrapper">
                        <div class="wrapper">
                            <span> {{ $t('quickCreator.websitePreview.changes') }} </span>
                            <mv-button class="borderless" text="quickCreator.websitePreview.button" :isLoading="buttonLoading" @click="goToEditor" />
                        </div>
                    </div>
                </div>
                <mv-icon-sprite />
            </div>
        </div>
    </div>
</template>

<script>
import { Headline, Preamble, MvButton, MvIcon, MvIconSprite } from "@monosolutions/vue-components";
import QCNavigationMixin from '../../mixins/QCNavigationMixin';
import { mapGetters } from 'vuex';
import axios from 'axios';

export default {
    name: 'qc-websitepreview',
    components: { Headline, Preamble, MvButton, MvIcon, MvIconSprite },
    mixins: [ QCNavigationMixin ],
    data() {
        return {
            stepReady: true,
            buttonLoading: false
        };
    },
    computed: {
        ...mapGetters({
            sessionId: 'sessionId',
            previewLink: 'previewLink'
        }),
        isMobile() {
            return this.$mq === 'mobile';
        },
        headlineSizeClass() {
            return this.isMobile ? 'mono-size-big' : 'mono-size-huge';
        },
        preambleSizeClass() {
            return this.isMobile ? 'mono-size-tiny' : 'mono-size-normal';
        },
        quickCreatorBaseUrl() {
            return process.env.VUE_APP_QC_BASE_URL;
        }
    },
    async mounted() {
        this.clearInputs();
    },
    methods: {
        async goToEditor() {
            this.buttonLoading = true;
            try {
                const response = await axios.get(`${this.quickCreatorBaseUrl}/editor-login`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'x-mono-sid': this.sessionId
                    }
                });
                if (response.data.siteLogin.fullLoginUrl !== "") {
                    window.open(response.data.siteLogin.fullLoginUrl, '_blank');
                    this.buttonLoading = false;
                }
            } catch (error) {
                console.error('GO TO EDITOR ONLY ERROR', error);
            }
        },

        copyLink() {
            const textArea = document.createElement('textarea');
            textArea.value = this.previewLink;
            document.body.appendChild(textArea);
            textArea.select();
            document.execCommand('copy');
            document.body.removeChild(textArea);
        }
    }
};
</script>

