<template>
    <div class="mainWrapper fullWidthContent">
        <component v-if="currentStep && currentStep.component" :is="currentStep.component" :step-name="currentStep.name" @next-step="nextStep" />
    </div>
</template>

<script>
import modules from '@/modules';
import { mapGetters } from 'vuex';
import QCNavigationMixin from '../mixins/QCNavigationMixin';

export default {
    name: 'step-wrapper',
    components: modules,
    mixins: [ QCNavigationMixin ],
    computed: {
        ...mapGetters({
            stepsDone: 'stepsDone',
            currentStepIndex: 'currentStepIndex'
        }),
        currentStep() {
            return this.allModules[this.currentStepIndex];
        },
    },
    mounted() {
        const step = this.$route.params.step;
        this.fetchStepData(step);
    },
    watch: {
        '$route'(to, from) {
            if (this.isMobile() && from.path !== to.path) {
                this.scrollToTop();
            }
        },
        '$route.params.step'(to) {
            // React to changes in the step parameter
            if (to > 0)
                this.fetchStepData(to);
        }
    },
    methods: {
        scrollToTop() {
            document.getElementById('qcApp').scrollTo(0, 0);
        },
        isMobile() {
            return this.$mq === 'mobile';
        },
        fetchStepData(step) {
            this.$store.dispatch('setCurrentStepIndex', step - 1);
        }
    }
};
</script>
