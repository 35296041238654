<template>
    <div class="moduleWrapper">
        <div class="sideimage">
            <img :src="'../images/' + sidebarGMBDetailsImg" class="illustration">
        </div>
        <div class="contents gmbDetails">
            <div class="fixedWrapper">
                <headline text="quickCreator.gmbDetails.headline" class="mds-heading-three" :size-class="headlineSizeClass" />
                <preamble text="quickCreator.gmbDetails.preamble" class="preamble" :size-class="preambleSizeClass" />
            </div>
            <div class="fixedWrapper">
                <div class="details-form">
                    <input-text 
                        name="email" 
                        class="mds-input-text" 
                        label="quickCreator.gmbDetails.form.email" 
                        index="email" 
                        :placeholderText="this.$t('quickCreator.gmbDetails.placeholder.email')"
                        :value="this.globalObject.email"
                        :required="true" 
                        :valid="formErrors.email === ''"
                        :error-message="formErrors.email"
                        @change="updateGlobalObject" />
                    <input-text
                        name="companyName"
                        class="mds-input-text"
                        label="quickCreator.gmbDetails.form.company_name"
                        index="companyName"
                        :placeholderText="this.$t('quickCreator.gmbDetails.placeholder.company_name')"
                        :value="this.globalObject.companyName"
                        iconPosition="right"
                        :required="true"
                        :valid="formErrors.companyName === ''"
                        :error-message="formErrors.companyName" 
                        @change="handleCompanyNameChange" />
                    <input-text 
                        name="street" 
                        class="mds-input-text" 
                        label="quickCreator.gmbDetails.form.street" 
                        index="street"
                        :placeholderText="this.$t('quickCreator.gmbDetails.placeholder.street')"
                        :value="this.globalObject.street"
                        :required="true" 
                        :valid="formErrors.street === ''"
                        :error-message="formErrors.street"
                        @change="updateGlobalObject" />
                    <input-phone-field
                        name="phone"
                        class="mds-input-text"
                        label="quickCreator.gmbDetails.form.phone"
                        index="phone"
                        :placeholder="this.$t('quickCreator.gmbDetails.placeholder.phone')"
                        :defaultCountry="currentCountryCode"
                        :value="this.globalObject.phone"
                        :required="true"
                        :valid="formErrors.phone === ''"
                        :error-message="formErrors.phone"
                        @changePhoneNumber="updateGlobalObjectPhone" />
                    <input-text
                        name="zip" 
                        class="mds-input-text" 
                        label="quickCreator.gmbDetails.form.zip" 
                        index="zip"
                        :placeholderText="this.$t('quickCreator.gmbDetails.placeholder.zip')"
                        :value="this.globalObject.zip"
                        :required="true" 
                        :valid="formErrors.zip === ''"
                        :error-message="formErrors.zip"
                        @change="updateGlobalObject" />
                    <input-text 
                        name="city" 
                        class="mds-input-text" 
                        label="quickCreator.gmbDetails.form.city" 
                        index="city"
                        :placeholderText="this.$t('quickCreator.gmbDetails.placeholder.city')"
                        :value="this.globalObject.city"
                        :required="true" 
                        :valid="formErrors.city === ''"
                        :error-message="formErrors.city"
                        @change="updateGlobalObject" />
                    <input-select
                        name="country"
                        class="mds-input-text mds-input-select"
                        label="quickCreator.gmbDetails.form.country"
                        :placeholderText="this.$t('quickCreator.gmbDetails.placeholder.country')"
                        index="country"
                        :value="this.selectedCountry"
                        :options="countriesList"
                        :required="true"
                        :valid="formErrors.country === ''"
                        :error-message="formErrors.country" 
                        @change="handleCountryChange" />
                    <input-text 
                        name="state"
                        class="mds-input-text"
                        label="quickCreator.gmbDetails.form.state"
                        index="state"
                        :placeholderText="this.$t('quickCreator.gmbDetails.placeholder.state')"
                        :value="this.globalObject.state"
                        :valid="formErrors.state === ''"
                        :error-message="formErrors.state"
                        @change="updateGlobalObject" />
                    <input-text 
                        name="orgNumber"
                        class="mds-input-text with-info"
                        label="quickCreator.gmbDetails.form.org_number"
                        index="orgNumber"
                        :info="companyOrgName"
                        :placeholderText="this.$t('quickCreator.gmbDetails.placeholder.org_number')"
                        :value="this.globalObject.orgNumber"
                        :valid="formErrors.orgNumber === ''"
                        :error-message="formErrors.orgNumber"
                        @change="updateGlobalObject" />
                    <input-text
                        name="customerId"
                        class="mds-input-text with-info"
                        label="quickCreator.gmbDetails.form.customerId"
                        index="customerId"
                        :info="customerIdInfo"
                        :placeholderText="this.$t('quickCreator.gmbDetails.placeholder.customerId')"
                        :value="this.accountObject.customerId"
                        :valid="formErrors.customerId === ''"
                        :error-message="formErrors.customerId"
                        @change="updateAccountObject" />
                </div>
                <p class="mandatory-paragraph">{{ $t("general.mandatory") }}</p>
            </div>
            <mv-icon-sprite />
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Joi from 'joi';
import QCModuleMixin from '../mixins/QCModuleMixin';
import { Headline, Preamble, InputText, InputSelect, MvIconSprite, InputPhoneField } from "@monosolutions/vue-components";

export default {
    name: 'qc-gmb-details',
    components: { Headline, Preamble, InputText, InputSelect, MvIconSprite, InputPhoneField },
    mixins: [ QCModuleMixin ],
    data() {
        return {
            globalObject: {
                companyName: "",
                phone: "",
                email: "",
                street: "",
                city: "",
                zip: "",
                state: "",
                country: "",
                orgNumber: ""
            },
            accountObject: {
                customerId: ""
            },
            formErrors: {
                companyName: "",
                phone: "",
                email: "",
                street: "",
                city: "",
                zip: "",
                state: "",
                country: "",
                orgNumber: "",
                customerId: ""
            },
            selectedCountry: null,
            savedCompanyName: "",
            phoneNumber: "",
            companyOrgName: {
                title: 'quickCreator.gmbDetails.tooltips.org_number.title',
                text: 'quickCreator.gmbDetails.tooltips.org_number.text',
                position: 'onTop'
            },
            customerIdInfo: {
                title: 'quickCreator.gmbDetails.tooltips.customer_id.title',
                text: 'quickCreator.gmbDetails.tooltips.customer_id.text',
                position: 'onTop'
            },
            sidebarGMBDetailsImg: '1x-Sailor.png'
        };
    },
    computed: {
        ...mapGetters({
            sessionId: 'sessionId',
            defaultCountry: 'defaultCountry',
            globalData: 'globalData',
            gmbPhoneCountryCode: 'gmbPhoneCountryCode',
            clearGlobalData: 'clearGlobalData'
        }),
        currentCountryCode() {
            return this.gmbPhoneCountryCode;
        },
        isMobile() {
            return this.$mq === 'mobile';
        },
        headlineSizeClass() {
            return this.isMobile ? 'mono-size-big' : 'mono-size-huge';
        },
        preambleSizeClass() {
            return this.isMobile ? 'mono-size-tiny' : 'mono-size-normal';
        },
        validationSchema() {
            const specialChars = 'æøåäöüÆØÅÄÖÜ';
            const customEmailValidation = (value, helpers) => {
                const emailRegex = new RegExp(
                    `^[a-zA-Z0-9_\\.\\-_+${specialChars}]+@[a-zA-Z0-9\\-${specialChars}]+\\.[a-zA-Z0-9\\-\\.]+$`, 'u'
                );

                if (!emailRegex.test(value)) {
                    return helpers.error('any.invalid');
                }

                return value;
            };
            return {
                companyName: Joi.string().empty().required(),
                phone: Joi.string().regex(/^[+]?[(]?\d{1,4}[)]?[-\s./0-9]*$/).required(),
                email: Joi.string().custom(customEmailValidation, 'Custom Email Validation').required(),
                street: Joi.string().empty().required(),
                city: Joi.string().empty().required(),
                zip: Joi.string().empty().required(),
                state: Joi.string().allow(''),
                country: Joi.string().empty().required(),
                orgNumber: Joi.string().allow(''),
                customerId: Joi.string().allow('')
            };
        }
    },
    mounted() {
        if (this.clearGlobalData) {
            const emptyGlobalData =  {
                companyName: '',
                phone: '',
                street: '',
                city: '',
                zip: '',
                state: '',
                country: ''
            };
            this.$store.dispatch('setGlobalData', emptyGlobalData);
            this.$store.dispatch('setGMBData', []);
            this.$store.dispatch('setStoredLocation', []);
        }

        Object.keys(this.globalObject).forEach(element => {
            this.globalObject[element] = this.globalData[element];
            if (element === 'companyName' && this.globalData[element]) {
                this.savedCompanyName = this.globalObject[element];
            }
        });

        this.selectedCountry = this.globalData.country ?  this.globalData.country : this.defaultCountry ? this.defaultCountry : '';
        this.globalObject.country = this.selectedCountry;
        this.phoneNumber = this.globalData.phone ? this.globalData.phone : '';
        this.canGoNext(this.globalObject);
    },
    methods: {
        handleCountryChange(payload) {
            this.selectedCountry = payload.data.value;
            this.updateGlobalObject(payload);
        },
        handleCompanyNameChange(payload) {
            this.savedCompanyName = payload.data.value;
            this.updateGlobalObject(payload);
        },
        updateGlobalObject(payload) {
            if (payload.index === 'email') {
                this.globalObject.email = payload.data.value.toLowerCase();
            } else {
                this.globalObject[payload.index] = payload.data.value;
            }
            this.setStepReady(false);
            this.saveGlobalData(payload);
        },
        updateAccountObject(payload) {
            this.accountObject[payload.index] = payload.data.value;
            this.$store.dispatch('setAccountData', this.accountObject);
        },
        updateGlobalObjectPhone(payload) {
            this.$nextTick(() => {
                if (document.getElementsByClassName('vti__flag')[0] && document.getElementsByClassName('vti__flag')[0].classList.length > 1) {
                    this.$store.dispatch('setGmbPhoneCountryCode', document.getElementsByClassName('vti__flag')[0]?.classList[1]);
                }
            });

            if (this.phoneNumber) {
                this.globalObject[payload.index] = this.phoneNumber;
            } else {
                this.updateGlobalObject(payload);
            }
        },
        async saveGlobalData(payload) {
            this.setStepReady(false);
            let { value } = payload.data;
            const checkField = this.validationSchema[payload.index].validate(value);
            let errorMap;
            if (checkField.error) {
                errorMap = {
                    "string.empty": this.$t("quickCreator.gmbDetails.errors.string.empty"),
                    "string.email": this.$t("quickCreator.gmbDetails.errors.email.empty"),
                    "string.pattern.base": this.$t("quickCreator.gmbDetails.errors.string.pattern")
                };
            }
            if (payload.index == 'zip' && checkField.error) {
                errorMap["string.pattern.base"] = this.$t("quickCreator.manualDetails.errors.string.zipPattern")
            }
            this.formErrors[payload.index] = checkField.error
                ? errorMap[checkField.error.details[0].type]
                : "";

            this.$store.dispatch('setGlobalData', this.globalObject);
            this.canGoNext(this.globalObject);
        },
        canGoNext(globalObject) {
            const checkAllFields = Joi.object().keys(this.validationSchema).validate(globalObject);
            if (checkAllFields.error) {
                this.setStepReady(false);
            } else {
                this.setStepReady(true);
                this.setStepDone(true);
            }
        }
    }
};
</script>