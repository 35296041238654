<template>
    <div :index="index" :class="[widthClass, customClass]" class="field input-multiselect label-above">
        <label v-if="label" class="mono-size-tiny">{{ $t(label) }}</label>
        <multiselect
            :class="classType"
            :value="value"
            :options="options"
            :searchable="searchable"
            :close-on-select="closeOnSelect"
            :show-labels="false"
            :multiple="multiple"
            track-by="value" label="text"
            :placeholder="placeholder"
            @select="onChange"
            @remove="$emit('remove', $event)"
        >
            <template slot="selection" slot-scope="{ values, isOpen }">
                <span v-if="values.length" v-show="!isOpen" class="multiselect__single">{{ values.length }} options selected</span>
            </template>
        </multiselect>
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect';

/**
 * Input multiselect
 */
export default {
    name: 'input-multiselect',
    components: { Multiselect },
    props: {
        index: {
            type: String,
            'default': ''
        },
        label: {
            type: String,
            'default': ''
        },
        value: {
            type: Object,
            'default': () => {}
        },
        classType: {
            type: String,
            'default': 'normal'
        },
        multiselectValue: {
            type: Array,
            'default': () => {
                return [];
            }
        },
        options: {
            type: Array,
            'default': () => {
                return [];
            }
        },
        disabled: {
            type: Boolean,
            'default': false
        },
        searchable: {
            type: Boolean,
            'default': false
        },
        multiple: {
            type: Boolean,
            'default': true
        },
        closeOnSelect: {
            type: Boolean,
            'default': false
        },
        placeholder: {
            type: String,
            'default': 'Select value'
        },
        required: {
            type: Boolean,
            'default': false
        },
    },
    computed: {
        widthClass() {
            let widthClass = `width-${this.width}`;

            if (this.offsetLeft !== null && this.width !== 100 && this.$el && this.$el.parentNode) {
                if (this.offsetLeft !== this.$el.parentNode.offsetLeft) {
                    widthClass = `${widthClass} notFirst`;
                }
            }
            return widthClass;
        },
        customClass() {
            let customClass = '';

            if (this.required) {
                customClass += 'required';
            }
            return customClass;
        }
    },
    methods: {
        onChange(e) {
            this.initialValue = e;
            const sentData = { index: this.index, data: { value: e.value } };
            this.$emit('change', sentData);
        },
    }
};
</script>
