<template>
    <div class="mainWrapper fullWidthContent">
        <div class="moduleWrapper loadingWrapper">
            <div class="sideimage">
                <img :src="'../images/' + sidebarLoadingImg" class="illustration">
            </div>
            <div class="contents loadingSite">
                <toaster size="small" :showToasterModal="showToaster" icon="alert-fill" :firstHeadline="errorMessageToaster" color="red" @modal-close-clicked="closeToaster" />
                <div class="fixedWrapper wide">
                    <headline text="quickCreator.loadingSite.headline" class="mds-heading-three" :size-class="headlineSizeClass" />
                    <preamble text="quickCreator.loadingSite.preamble" class="preamble" :size-class="preambleSizeClass" />
                </div>
                <div class="loadingWrap">
                    <loader size="large" color="blue" isLoading />
                </div>
                <div class="loadingMessageContainer">
                    <transition name="slide-fade" mode="out-in">
                        <p class="loading-text" :key="currentMessage">{{ $t(currentMessage) }}</p>
                    </transition>
                </div>
                <mv-icon-sprite />
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { Headline, Preamble, MvIconSprite, Toaster, Loader } from "@monosolutions/vue-components";
import { mapGetters } from 'vuex';

export default {
    name: 'qc-loadingsite',
    components: { Headline, Preamble, MvIconSprite, Toaster, Loader },
    data() {
        return {
            stepReady: true,
            showToaster: false,
            errorMessageToaster: '',
            currentMessage: 'quickCreator.loadingSite.loadingMessage.creatingSite',
            sidebarLoadingImg: '1x-Piechart.png'
        };
    },
    computed: {
        ...mapGetters({
            sessionId: 'sessionId',
            globalData: 'globalData',
            accountData: 'accountData',
            templateId: 'templateId',
            AIData: 'AIData',
            isAIFlow: 'isAIFlow',
            imagesToSend: 'imagesToSend'
        }),
        isMobile() {
            return this.$mq === 'mobile';
        },
        headlineSizeClass() {
            return this.isMobile ? 'mono-size-big' : 'mono-size-huge';
        },
        preambleSizeClass() {
            return this.isMobile ? 'mono-size-tiny' : 'mono-size-normal';
        },
        quickCreatorBaseUrl() {
            return process.env.VUE_APP_QC_BASE_URL;
        }
    },
    async mounted() {
        await this.createSite();
        if (this.isAIFlow) {
            await this.uploadImages();
            this.currentMessage = 'quickCreator.loadingMessage.parsingAiData';
            await this.parseAiData();
        }
        this.currentMessage = 'quickCreator.loadingMessage.publishing';
        await this.publishSite();
        this.currentMessage = 'quickCreator.loadingMessage.finishing';
        await this.getPublishState(true);
    },
    methods: {
        async createSite() {
            const createSiteData = { 
                globalData: { ...this.globalData }, 
                accountData: this.accountData, templateId: this.templateId 
            };

            try {
                await axios.post(`${this.quickCreatorBaseUrl}/site`, createSiteData,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'x-mono-sid': this.sessionId
                        }
                    });
            } catch (error) {
                if (error.response && error.response.status === 500) {
                    this.showToaster = true;
                    this.errorMessageToaster = 'quickCreator.loadingSite.errorConnecting';
                } else {
                    console.error('CREATE SITE ERROR', error);
                    throw error;
                }
            }
        },
        async uploadImages() {
            const imagesData = this.filterNullImages(this.imagesToSend);

            try {
                await axios.post(`${this.quickCreatorBaseUrl}/bulk-image-upload`, imagesData,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'x-mono-sid': this.sessionId
                        }
                    });
            } catch (error) {
                if (error.response && error.response.status === 500) {
                    this.showToaster = true;
                    this.errorMessageToaster = 'quickCreator.loadingSite.imagesUploadError';
                } else {
                    console.error('IMAGES UPLOAD ERROR', error);
                    throw error;
                }
            }
        },
        async parseAiData() {
            try {
                await axios.post(`${this.quickCreatorBaseUrl}/update-site`, {
                    aiData: {
                        ...this.AIData,
                    },
                    templateId: this.templateId
                }, {
                    headers: {
                        'Content-Type': 'application/json',
                        'x-mono-sid': this.sessionId
                    }
                });
            } catch (error) {
                if (error?.response?.status === 500) {
                    this.showToaster = true;
                    this.errorMessageToaster = 'quickCreator.loadingSite.errorConnecting';
                } else if (error.request) {
                    if (error.message === 'Network Error') {
                        await this.getPublishState(false);
                    }
                } else {
                    console.error('CREATE SITE ERROR', error);
                    throw error;
                }
            }
        },
        async publishSite() {
            const publishSiteData = { action: 'publish' };

            try {
                await axios.post(`${this.quickCreatorBaseUrl}/site-publish`, publishSiteData,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'x-mono-sid': this.sessionId
                        }
                    });
            } catch (error) {
                console.error('PUBLISH SITE ERROR', error);
            }
        },
        async getPublishState(afterPublish) {
            const retryInterval = 5000;
            const maxRetries = 20;
            let retryCount = 0;

            while (retryCount < maxRetries) {
                try {
                    const response = await axios.get(`${this.quickCreatorBaseUrl}/publish`, {
                        headers: {
                            'Content-Type': 'application/json',
                            'x-mono-sid': this.sessionId
                        }
                    });
                    const finishedSteps = response?.data?.finishedSteps;

                    if (afterPublish && response?.data?.published !== "NOK") {
                        this.$store.dispatch('setPreviewLink', response.data.published);
                        this.$router.push('/preview').catch(() => { });
                        return;
                    }
                    
                    if (!afterPublish && finishedSteps.step_3 === true) {
                        return;
                    }

                    if (!afterPublish && finishedSteps.step_errored === "step_3" && finishedSteps.step_3 === false) {
                        this.showToaster = true;
                        this.errorMessageToaster = 'quickCreator.loadingSite.errorConnecting';
                        throw new Error('Error in step_3.');
                    }
                } catch (error) {
                    console.error('getPublishState ERROR', error);
                    this.showToaster = true;
                    this.errorMessageToaster = 'quickCreator.loadingSite.errorConnecting';
                }

                await new Promise(resolve => setTimeout(resolve, retryInterval)); // Wait 5 seconds and retry
                retryCount++;
            }
            console.error('getPublishState exceeded maximum retries');
        },
        closeToaster() {
            this.showToaster = false;
        },
        filterNullImages(images) {
            const imagesCopy = images;
            for (const [key, values] of Object.entries(images)) {
                for (const innerKey of Object.keys(values)) {
                    imagesCopy[key][innerKey] = images[key][innerKey].filter(value => !!value);
                }
            }
            return imagesCopy;
        }
    }
};
</script>

