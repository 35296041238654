<template>
    <div class="templateFilter"> 
        <div class="templateDoubleChoice">
            <double-choice 
                choice-one="quickCreator.templatePreview.standard"
                choice-two="quickCreator.templatePreview.ai"
                size="small"
                :pre-selected-choice="isAIFlow ? 'quickCreator.templatePreview.ai' : 'quickCreator.templatePreview.standard'"
                :disabled="!isAIEnabled"
                @choice-selected="choiceSelected" />
        </div>
        <multiselect
            class="grey"
            v-model="value"
            :options="tags"
            :searchable="false"
            :close-on-select="false"
            :show-labels="false"
            :multiple="true"
            track-by="name" label="name"
            :placeholder="this.$t('quickCreator.templatePreview.placeholder')"
            :disabled="dropdownDisabled"
            @select="addFilterTag"
            @remove="removeFilterTag">
                <template slot="selection" slot-scope="{ values, isOpen }">
                    <span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} options selected</span>
                </template>
		</multiselect>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import TemplateSelectMixin from '../mixins/TemplateSelectMixin';
import industriesJSON from '../assets/json/industries.json'
import { DoubleChoice } from "@monosolutions/vue-components";
import Multiselect from 'vue-multiselect';

export default {
    name: 'template-filter',
    components: { Multiselect, DoubleChoice },
    mixins: [ TemplateSelectMixin ],
    props: {
        tagTemplates: {
            type: Array,
            'default': ()=>{
                return [];
            }
        },
        hasToggle: {
            type: Boolean,
            'default': false,
        },
        title: {
            type: String,
            'default': ''
        }
    },
    data() {
        return  {
            filterData: [],
            visibleFilters: true,
            industriesList: [],
            value: null,
            preSelectedChoice: '',
            dropdownDisabled: false
        };
    },
    computed: {
        ...mapGetters({
            isAIFlow: 'isAIFlow',
            isAIEnabled: 'isAIEnabled'
        }),
        tags() {
            const tagData = [];
            this.tagTemplates.forEach(template => {
                if (!template.templateHeadings) {
                    return;
                }
                template.templateHeadings.forEach(heading => {
                    const splitTag = heading.translationKey.replace(/\s/g, '');
                    if (!tagData.includes(splitTag)) {
                        tagData.push(splitTag);
                    }
                });
            });
            const options = tagData.map(tag => ({
                name: this.$t(`general.themes.tags.`+ tag),
                tag: tag
            }));
            options.sort((a, b) => a.name.localeCompare(b.name));
            return options;
        },
        headlineIcon() {
            if (this.hasToggle) {
                return this.visibleFilters ? 'mf-angle-up-s' : 'mf-angle-down-s';
            }
            return '';
        }
    },
    watch: {
        '$mq'(to, from) {
            if (from !== to && to !== 'mobile' && !this.visibleFilters) {
                this.visibleFilters = true;
            }
        }
    },
    mounted() {
        this.industriesList = industriesJSON.tags;
        this.visibleFilters = this.$mq !== 'mobile';
        if (this.isAIFlow) {
            this.dropdownDisabled = true;
            this.$emit('filterUpdated', 'AI');
            this.$store.dispatch('setIsAIFlow', true);
        } else {
            this.dropdownDisabled = false;
            this.$emit('filterUpdated', 'STANDARD');
            this.$store.dispatch('setIsAIFlow', false);
        }
    },
    methods: {
        toggleVisible() {
            if (this.hasToggle) {
                this.visibleFilters = !this.visibleFilters;
            }
        },
        addFilterTag(selectedOption){
            this.filterData.push(selectedOption.tag);
            this.$emit('filterUpdated', this.filterData);
        },
        removeFilterTag(removedOption){
            this.filterData.splice(this.filterData.indexOf(removedOption.tag), 1);
            this.$emit('filterUpdated', this.filterData);
        },
        choiceSelected(payload) {
            const typeSplit = payload.split('.');
            if (typeSplit.length > 1) {
                this.clearTemplate();
                const type = typeSplit[typeSplit.length - 1];
                if (type === 'ai') {
                    this.dropdownDisabled = true;
                    this.$emit('filterUpdated', type.toUpperCase());
                    this.filterData = [];
                    this.value = null;
                    this.$emit('filterUpdated', this.filterData);
                    this.$store.dispatch('setIsAIFlow', true);
                } else {
                    this.dropdownDisabled = false;
                    this.$emit('filterUpdated', type.toUpperCase());
                    this.$store.dispatch('setIsAIFlow', false);
                }
            }
        }
    }
};
</script>
