/**
 * The file enables `@qc/modules/index.js` to import all quick creator modules
 * in a one-shot manner. There should not be any reason to edit this file.
 */

// eslint-disable-next-line
//if(TESTING){ require.context = require('@/utils/requireContext').default; }

const files = require.context('.', false, /\.vue$/);
const components = {};

files.keys().forEach(key => {
    const fileName = key.replace(/(\.\/|\.vue)/g, '');

    components[fileName] = files(key).default;

});

export default components;