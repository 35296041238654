<template>
    <div>
        <div v-if="isLoadingAiFields" class="loadingWrap">
            <loader size="large" color="blue" isLoading />
        </div>
        <div v-if="!isLoadingAiFields">
            <!-- Static Fields -->
            <input-multiselect
                name="businessType"
                class="mvc-field mds-input-text mds-input-select business-type normal"
                label="quickCreator.moreInformation.form.businessType.label"
                index="businessType"
                :options="businessTypes"
                :value="multiselectBusinessType"
                :required="true"
                :width="100"
                searchable
                :multiple="false"
                :placeholder="$t('general.pleaseSelect')"
                closeOnSelect
                @change="updateGlobalObject" />

            <input-multiselect
                name="siteLanguage"
                :value="multiselectLanguage"
                class="mvc-field mds-input-text site-language"
                :width="100"
                label="quickCreator.moreInformation.form.siteLanguage.label"
                index="siteLanguage"
                :options="languages"
                :required="true"
                searchable
                :multiple="false"
                :placeholder="$t('general.pleaseSelect')"
                closeOnSelect
                @change="updateGlobalObject" 
            />

            <input-text 
                name="startDate"
                class="mds-input-text start-date with-info"
                label="quickCreator.moreInformation.form.startDate.label"
                index="startDate"
                :info="companyStartDate"
                :placeholderText="this.$t('quickCreator.moreInformation.placeholder.start_date')"
                :value="this.globalObject.startDate"
                @change="updateGlobalObject" />

            <!-- Template specific fields -->
            <template>
                <div v-for="(field, i) in templateSpecificContent" :key="i">
                    <component 
                        :is="field.tag" 
                        :index="field.index"
                        :value="globalObject[field.name]"
                        :class="field.class"
                        :label="field.label"
                        :required="field.required" 
                        :info="field.info ? field.info : {}"
                        :valid="field.isValid"
                        :error-message="field.errorMessage ? field.errorMessage : ''"
                        :helpText="field.helpText ? field.helpText : ''"
                        @change="updateGlobalObject"
                    />
                </div>
            </template>

            <file
                v-if="showDropzone"
                label="quickCreator.moreInformation.form.fileField.label"
                dropzoneMessage="quickCreator.moreInformation.form.fileField.dropMessage"
                :required="false"
                :urlUpload="false"
                :addFromFileLibrary="false"
                :options="imageOptions.files"
                fileApiEndpoint="false"
                siteUrl="false"
                :event-bus="false"
                @change="uploadComplete"
            />
            <div v-if="imageData">
                <div class="mvc-field mvc-file label-above">
                    <label class="mono-size-tiny">Logo</label>
                </div>
                <div class="image-container">
                    <span class="red-circle" @click="deleteImage">
                        <span class="white-cross"></span>
                    </span>
                    <img :src="imageData" alt="Uploaded Image" width="120" height="120" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import QCModuleMixin from '../mixins/QCModuleMixin';
import { Headline, Preamble, InputText, InputTextArea, MvIconSprite, InputSelect, MvButton, File, Loader } from "@monosolutions/vue-components";
import InputMultiselect from '../components/InputMultiselect.vue';
import { mapGetters } from 'vuex';
import FileExtensions from '@/utils/fileExtensions';
const fileExtensions = new FileExtensions();
import Multiselect from 'vue-multiselect';
import axios from 'axios';

export default {
    name: 'qc-more-information-new',
    components: { Multiselect, Headline, InputText, InputTextArea, InputSelect, Preamble, MvIconSprite, MvButton, InputMultiselect, File, Loader },
    mixins: [ QCModuleMixin ],
    data() {
        return {
            globalObject: {
                mission: "",
                businessType: "",
                services: "",
                products: "",
                descriptionShort: "",
                siteLanguage: "",
                logo: "",
                startDate: ""
            },
            shortInformation: {
                text: 'quickCreator.moreInformation.form.shortInformation',
                position: 'onTop'
            },
            longInformation: {
                text: 'quickCreator.moreInformation.form.longInformation',
                position: 'onTop'
            },
            companyStartDate: {
                title: 'quickCreator.moreInformation.tooltips.start_date.title',
                text: 'quickCreator.moreInformation.tooltips.start_date.text',
                position: 'onTop'
            },
            imageData: null,
            imageOptions: {
                files: {
                    createImageThumbnails: true,
                    maxFiles: 1,
                    maxFilesize: 20,
                    acceptedFiles: fileExtensions.getAsString()
                }
            },
            showDropzone: true,
            templateSpecificContent: [],
            missionWords: 0,
            fetchedAiFields: [],
            isLoadingAiFields: true
        };
    },
    computed: {
        ...mapGetters({
            sessionId: 'sessionId',
            globalData: 'globalData',
            isAIFlow: 'isAIFlow',
            defaultLanguage: 'defaultLanguage',
            templateId: 'templateId'
        }),
        checkRequiredFieldsFilled() {
            let commonFieldsFilled = this.globalObject.businessType !== "" && this.globalObject.siteLanguage !== "" && this.globalObject.businessType !== undefined && this.globalObject.siteLanguage !== undefined;
            let enoughMissionWords = this.missionWords >= 5;
            for (const field of this.templateSpecificContent) {
                if (field.required) {
                    if (field.name === 'mission' && !enoughMissionWords) {
                        return false;
                    }
                    if (!this.globalObject[field.name] || this.globalObject[field.name].trim() === '') {
                        return false;
                    }
                }
            }
            return commonFieldsFilled;
        },
        quickCreatorBaseUrl() {
            return process.env.VUE_APP_QC_BASE_URL;
        }
    },
    async mounted() {
        await this.init();
        Object.keys(this.globalObject).forEach(element => {
            this.globalObject[element] = this.globalData[element];
        });

        this.setStepDone(true);
        this.missionWords = this.countWords(this.globalObject.mission);
        this.setStepReady(this.checkRequiredFieldsFilled);
        const storedImage = localStorage.getItem('uploadedImage');
        if (storedImage) {
            this.showDropzone = false;
            this.imageData = storedImage;
        }
    },
    methods: {
        updateGlobalObject(payload) {
            this.globalObject[payload.index] = payload.data.value;
            if (this.isAIFlow && payload.index === 'mission') {
                this.checkNumberOfWords();
            }
            this.saveGlobalData();
        },

        async saveGlobalData() {
            this.$store.dispatch('setGlobalData', this.globalObject);
            this.setStepReady(this.checkRequiredFieldsFilled);
        },

        async init() {
            if (this.isAIFlow) {
                this.fetchedAiFields = await this.fetchAIFields();
                this.templateSpecificContent = this.chooseFields(this.fetchedAiFields);
            } else {
                this.isLoadingAiFields = false;
                this.templateSpecificContent = [
                    {
                        tag: "input-text",
                        name: "mission",
                        class: "mds-input-text mission",
                        width: 100,
                        label: "quickCreator.moreInformation.form.mission.label",
                        index: "mission",
                        value: "",
                        required: false,
                        change: this.updateGlobalObject
                    },
                    {
                        tag: "input-text",
                        name: "descriptionShort",
                        class: "mds-input-text description_short with-info",
                        width: 100,
                        label: "quickCreator.moreInformation.form.description_short.label",
                        index: "descriptionShort",
                        value: "",
                        required: false,
                        info: this.shortInformation,
                        change: this.updateGlobalObject
                    },
                    {
                        tag: "input-text-area",
                        name: "descriptionLong",
                        class: "mds-input-text description_long with-info",
                        width: 100,
                        label: "quickCreator.moreInformation.form.description_long.label",
                        index: "descriptionLong",
                        value: "",
                        required: false,
                        info: this.longInformation,
                        change: this.updateGlobalObject
                    }
                ]
            }
        },

        uploadComplete(file) {
            this.showDropzone = false;
            this.imageData = file.data.value;
            const payload = {
                index: "logo",
                data: {
                    value: this.imageData
                }
            };
            this.updateGlobalObject(payload);
        },

        deleteImage() {
            this.showDropzone = true;
            this.imageData = null;
            window.localStorage.removeItem('uploadedImage');
        },

        checkNumberOfWords() {
            this.missionWords = this.countWords(this.globalObject.mission);
            const missionElementIndex  = this.templateSpecificContent.findIndex(element => element.name === 'mission');
            if (missionElementIndex !== -1) {
                this.templateSpecificContent[missionElementIndex].isValid = this.missionWords >= 5;
            }
            return this.missionWords >= 5;
        },

        async fetchAIFields() {
            try {
                const fields = await axios.post(`${this.quickCreatorBaseUrl}/template-questions`, { templateId: this.templateId },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'x-mono-sid': this.sessionId
                        }
                    }
                );
                this.isLoadingAiFields = false;
                return fields.data.questions;
            } catch(error) {
                console.log(error);
            }
        }
    }
};
</script>