<template>
    <div class="moduleWrapper">
        <div class="sideimage">
            <img :src="'../images/' + sidebarGMBSearchImg" class="illustration">
        </div>
        <div class="contents gmbSearch">
            <toaster size="small" :showToasterModal="showToaster" icon="alert-fill" :firstHeadline="errorMessageToaster" color="red" @modal-close-clicked="closeToaster" />
            <div class="fixedWrapper">
                <headline text="quickCreator.gmbSearch.headline" class="mds-heading-three" :size-class="headlineSizeClass" />
                <preamble text="quickCreator.gmbSearch.preamble" class="preamble" :size-class="preambleSizeClass" />
            </div>
            <div class="fixedWrapper search-wrapper">
                <div class="search-form">
                    <input-select
                        name="country"
                        class="mds-input-text mds-input-select"
                        label="quickCreator.gmbSearch.form.search_region"
                        index="country"
                        :value="this.selectedCountry"
                        :options="countriesList"
                        :required="true"
                        @change="handleCountryChange" />
                    <input-text
                        name="companyName" 
                        class="mds-input-text" 
                        label="quickCreator.gmbSearch.form.company_name" 
                        index="companyName" 
                        :value="this.globalObject.companyName"
                        iconPosition="right"
                        :required="true"
                        @change="handleCompanyNameChange" />
                </div>
                <div class="search-details">
                    <mv-button
                        class="button"
                        size="medium"
                        text="quickCreator.gmbSearch.button"
                        :disabled="!canSearchCompany"
                        :isLoading="searchLoading"
                        @click="searchGMBCompany" />
                    <p class="mandatory-paragraph">{{ $t("quickCreator.gmbSearch.mandatory") }}</p>
                </div>
                <div class="line"></div>
            </div>
            <div class="fixedWrapper search-results">
                <div v-for="(entry, index) in gmbData"
                    :key="index"
                    :class="['gmb-entry', { 'hide-phone': Object.keys(entry.location.phoneNumbers).length === 0 }, { 'selected': selectedCompany === entry.location }]"
                    @click="handleEntryClick(entry, index)" >
                        <Card
                            :index="index"
                            :title="entry.location.title"
                            :headline="entry.location.phoneNumbers?.primaryPhone"
                            :secondHeadline="entry.location.storefrontAddress?.addressLines?.join(', ')"
                            :thirdHeadline="entry.location.storefrontAddress?.postalCode"
                            @click="cardClick">
                        </Card>
                </div>
            </div>
            <div v-if="companiesFoundModal.show" class="modal-overlay">
                <mv-modal
                    :description="companiesFoundModal.preamble"
                    :class="{'cantShow': cantSearch}"
                    size="medium"
                    :firstButton="false"
                    :secondButton="true"
                    :secondButtonText="companiesFoundModal.secondButtonText"
                    :secondButtonDisabled="companiesFoundModal.secondButtonDisabled"
                    @second-button-clicked="closeCompaniesFoundModal"
                    @modal-close-clicked="closeCompaniesFoundModal">
                </mv-modal>
            </div>
            <mv-icon-sprite />
        </div>
    </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from 'vuex';
import QCModuleMixin from '../mixins/QCModuleMixin';
import { Headline, Preamble, InputText, InputSelect, MvIconSprite, MvButton, Toaster, Card, MvModal } from "@monosolutions/vue-components";

export default {
    name: 'qc-gmb-search',
    components: { Headline, Preamble, InputText, InputSelect, MvIconSprite, MvButton, Toaster, Card, MvModal },
    mixins: [ QCModuleMixin ],
    data() {
        return {
            globalObject: {
                companyName: "",
                country: ""
            },
            searchLoading: false,
            searchCompany: '',
            selectedCountry: null,
            companyName: "",
            gmbData: [],
            companiesFoundModal: {
                show: false,
                title: 'quickCreator.gmbDetails.companiesFoundModal.title',
                preamble: '',
                secondButtonText: 'quickCreator.gmbDetails.companiesFoundModal.button',
                secondButtonDisabled: true
            },
            selectedCompany: [],
            phoneNumber: "",
            showToaster: false,
            errorMessageToaster: '',
            noSearchCountries: [],
            cantSearch: false,
            sidebarGMBSearchImg: '1x-Sailor.png',
            cardSelected: [],
            prevCardSelected: 0
        };
    },
    computed: {
        ...mapGetters({
            sessionId: 'sessionId',
            defaultCountry: 'defaultCountry',
            globalData: 'globalData',
            GMBData: 'GMBData',
            storedLocation: 'storedLocation'
        }),
        canSearchCompany() {
            return !this.cantSearch && this.companyName !== "" && this.selectedCountry;
        },
        isMobile() {
            return this.$mq === 'mobile';
        },
        headlineSizeClass() {
            return this.isMobile ? 'mono-size-big' : 'mono-size-huge';
        },
        preambleSizeClass() {
            return this.isMobile ? 'mono-size-tiny' : 'mono-size-normal';
        }
    },
    mounted() {
        Object.keys(this.globalObject).forEach(element => {
            this.globalObject[element] = this.globalData[element];
            if (element === 'companyName' && this.globalData[element]) {
                this.companyName = this.globalObject[element];
                this.setStepReady(true);
            }
        });

        if (this.GMBData.length > 0) {
            this.gmbData = this.GMBData;
            this.selectedCompany = this.storedLocation;
        }

        this.noSearchCountries = this.findDifferentCountries();
        this.selectedCountry = this.globalData.country ? this.globalData.country : this.defaultCountry ? this.defaultCountry : '';
        this.globalObject.country = this.selectedCountry;
        this.$store.dispatch('setGmbPhoneCountryCode', this.selectedCountry?.toLowerCase());
        this.cantSearch = this.noSearchCountries.some((country) => country.value === this.globalObject.country);
        this.setStepDone(true);
    },
    methods: {
        handleCountryChange(payload) {
            this.cantSearch = this.noSearchCountries.some((country) => country.value === payload.data.value);
            if (this.cantSearch) {
                this.throwErrorMessage();
            }
            this.selectedCountry = payload.data.value;
            this.updateGlobalObject(payload);
        },
        handleCompanyNameChange(payload) {
            this.companyName = payload.data.value;
            this.updateGlobalObject(payload);
        },
        updateGlobalObject(payload) {
            this.globalObject[payload.index] = payload.data.value;
        },
        cardClick(payload) {
            if (this.prevCardSelected !== payload.index && !payload.data.isSelected) {
                this.cardSelected = {
                    index: payload.index,
                    data: {
                        isSelected: !payload.data.isSelected
                    }
                };
            } else {
                this.cardSelected = payload;
            }
        },
        handleEntryClick(entry, index) {
            if (this.cardSelected.data.isSelected && this.cardSelected.index === index) {
                this.selectedCompany = entry.location;
                this.companyName = entry.location.title;
                this.phoneNumber = this.selectedCompany.phoneNumbers?.primaryPhone;
                this.fillGlobalObject(this.selectedCompany);
                this.$store.dispatch('setStoredLocation', entry.location);
                this.$store.dispatch('setGmbPhoneCountryCode', this.selectedCountry?.toLowerCase());
                this.$store.dispatch('setGlobalData', this.globalObject);
                this.$store.dispatch('setClearGlobalData', false);
                this.prevCardSelected = index;
                this.setStepReady(true);
            } else if (!this.cardSelected.data.isSelected && this.cardSelected.index === index) {
                this.selectedCompany = [];
                this.setStepReady(false);
                this.fillGlobalObject(this.selectedCompany);
                this.$store.dispatch('setClearGlobalData', true);
                return;
            }
        },
        fillGlobalObject(selectedCompany) {
            this.globalObject = {
                email: '',
                companyName: selectedCompany.title || "",
                phone: selectedCompany.phoneNumbers?.primaryPhone || "",
                street: selectedCompany.storefrontAddress?.addressLines[0] || "",
                city: selectedCompany.storefrontAddress?.locality || "",
                zip: selectedCompany.storefrontAddress?.postalCode || "",
                state: selectedCompany.storefrontAddress?.regionCode || "",
                country: this.selectedCountry || ""
            };
        },
        async searchGMBCompany() {
            this.searchLoading = true;
            this.setStepReady(false);
            const quickCreatorBaseUrl = process.env.VUE_APP_QC_BASE_URL;
            const gbpData = { companyName: this.companyName, countryCode: this.selectedCountry };

			try {
				const response = await axios.post(`${quickCreatorBaseUrl}/gbp`, gbpData,
				{
					headers: {
						"Content-Type": "application/json",
                        'x-mono-sid': this.sessionId
					},
				});

                this.gmbData = response.data.googleLocations;
                this.$store.dispatch('setGMBData', this.gmbData);
                this.searchLoading = false;
			} catch (error) {
                this.searchLoading = false;
                this.showToaster = true;
                this.errorMessageToaster = 'quickCreator.gmbDetails.companiesFoundModal.errorConnecting';
				throw error;
			}

            if (!this.gmbData) {
                this.companiesFoundModal.show = true;
                this.companiesFoundModal.title = 'quickCreator.gmbDetails.companiesFoundModal.errorTitle';
                this.companiesFoundModal.preamble = 'quickCreator.gmbDetails.companiesFoundModal.noCompaniesFound';
                this.companiesFoundModal.secondButtonText = 'quickCreator.gmbDetails.companiesFoundModal.errorButton';
                this.companiesFoundModal.secondButtonDisabled = false;
            }
        },
        throwErrorMessage() {
            this.gmbData = [];
            this.companiesFoundModal.show = true;
            this.companiesFoundModal.preamble = 'quickCreator.gmbDetails.companiesFoundModal.cantSearchPreamble';
            this.companiesFoundModal.secondButtonText = 'quickCreator.gmbDetails.companiesFoundModal.errorButton';
            this.companiesFoundModal.secondButtonDisabled = false;
        },
		closeCompaniesFoundModal() {
			this.companiesFoundModal.show = false;
            this.companiesFoundModal.preamble = '';
		},
        closeToaster() {
            this.showToaster = false;
        }
    }
};
</script>