import Vue from 'vue';
import Router from 'vue-router';
import { $ss } from '@/plugins/localstorage';
import LoginForm from '../views/login/LoginForm.vue';
import WebsitePreview from '../views/preview/WebsitePreview.vue';
import LoadingSite from '../views/loading/LoadingSite.vue';
import StepWrapper from '../components/StepWrapper.vue';

Vue.use(Router);

const routes = [
    {
        path: '/',
        name: 'home',
        redirect: {
            name: 'login'
        }
    },
    {
        path: '/login',
        name: 'login',
        component: LoginForm,
        meta: {
            guest: true
        }
    },
    {
        path: '/preview',
        name: 'preview',
        component: WebsitePreview,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/loading',
        name: 'loading',
        component: LoadingSite,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/step/:step',
        name: 'step',
        component: StepWrapper,
        meta: {
            requiresAuth: true
        }
    }
]

const router = new Router({
    mode: 'history',
    base: '/',
    routes
});

router.beforeEach((to, from, next) => {
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
    const loggedin = $ss.get('loginToken') !== '' ? true : false;

    if (requiresAuth && !loggedin) {
        next({
            path: '/login',
            params: { nextUrl: to.fullPath }
        });
    } else {
        if (!loggedin) {
            next();
        }
        else {
            next();
        }
    }
});

export default router;