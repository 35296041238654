import { mapGetters } from 'vuex';
import axios from 'axios';
import countriesListJSON from '../assets/json/all-countries.json'
import countriesJSONGBP from '../assets/json/countries-gbp.json'
import businessTypesJSON from '../assets/json/industries.json'
import languagesJSON from '../assets/json/languages.json'

export default {
    computed: {
        ...mapGetters({
            allStandardModules: 'modules',
            currentStepIndex: 'currentStepIndex',
            lastStep: 'lastStep',
            isAIFlow: 'isAIFlow',
            defaultLanguage: 'defaultLanguage',
            globalData: 'globalData',
            accountData: 'accountData',
            sessionId: 'sessionId',
            templateId: 'templateId',
        }),
        allModules() {
            return this.allStandardModules.map((_module, i) => {
                if (_module.aiFlow && this.isAIFlow) {
                    _module.nextStep = _module.aiFlow;
                    delete _module.aiFlow;
                }

                return {
                    ..._module,
                    step: i
                }
            });
        },
        multiselectLanguage() {
            this.globalObject.siteLanguage = this.globalObject.siteLanguage ? this.globalObject.siteLanguage : this.defaultLanguage;
            if (this.globalObject && this.globalObject.siteLanguage !== "") {
                return this.languages.find(obj => obj.value === this.globalObject.siteLanguage);
            }
            return this.languages.find(obj => obj.value === this.defaultLanguage);
        },
        multiselectBusinessType() {
            if (this.globalObject && this.globalObject.businessType !== "") {
                return this.businessTypes.find(obj => obj.value === this.globalObject.businessType);
            }
        }
    },
    data() {
        return {
            stepIndex: 0,
            stepReady: false,
            stepSettings: {
                hideNext: false,
                hideFooter: false
            },
            countriesList: [],
            countriesGBP: [],
            businessTypes: [],
            languages: [],
            aiFields: [
                {
                    tag: "input-text",
                    name: "mission",
                    class: "mds-input-text mission",
                    width: 100,
                    label: "quickCreator.moreInformation.form.mission.label",
                    index: "mission",
                    value: "",
                    required: true,
                    change: this.updateGlobalObject,
                    isValid: true,
                    errorMessage: "quickCreator.moreInformation.form.mission.errors"
                },
                {
                    tag: "input-text",
                    name: "services",
                    class: "mds-input-text services",
                    width: 100,
                    label: "quickCreator.moreInformation.form.services.label",
                    index: "services",
                    value: "",
                    required: true,
                    helpText: "quickCreator.moreInformation.form.services.helpText",
                    isValid: true,
                    change: this.updateGlobalObject
                },
                {
                    tag: "input-text",
                    name: "products",
                    class: "mds-input-text products",
                    width: 100,
                    label: "quickCreator.moreInformation.form.products.label",
                    index: "products",
                    value: "",
                    required: true,
                    helpText: "quickCreator.moreInformation.form.products.helpText",
                    isValid: true,
                    change: this.updateGlobalObject
                },
                {
                    tag: "input-text",
                    name: "descriptionShort",
                    class: "mds-input-text description_short",
                    width: 100,
                    label: "quickCreator.moreInformation.form.description_short.label",
                    index: "descriptionShort",
                    value: "",
                    required: true,
                    isValid: true,
                    change: this.updateGlobalObject
                }
            ],
            menuRoutes: {
                text: {
                    name: "Text",
                    icon: "globe",
                    isConfirmed: false
                },
                testimonials: {
                    name: "Testimonials",
                    icon: "star-stroke",
                    isConfirmed: false
                },
                products: {
                    name: "Products",
                    icon: "products",
                    isConfirmed: false
                },
                services: {
                    name: "Services",
                    icon: "shopping-cart",
                    isConfirmed: false
                },
                gallery: {
                    name: "Gallery",
                    icon: "image",
                    isConfirmed: false
                }
            },
        };
    },
    mounted() {
        this.businessTypes = Object.entries(businessTypesJSON.tags)
            .map(type => {
                return {
                    "text": this.$t(`general.themes.tags.`+ type[0]),
                    "value": type[0]
                }
            });

        this.countriesList = Object.entries(countriesListJSON.countries)
            .map(country => {
                return {
                    "text": this.$t(`general.countries.`+ country[1].value),
                    "value": country[1].value
                }
            });

        this.languages = Object.entries(languagesJSON.languages)
            .map(lang => {
                return {
                    "text": this.$t(`general.languages.`+ lang[1].value),
                    "value": lang[1].value
                }
            });

        this.countriesList.unshift({
            "text": this.$t("general.pleaseSelect"),
            "value": ""
        });

        this.countriesGBP = countriesJSONGBP.countries;
        this.stepIndex = (this.$route?.params?.step ? this.$route.params.step : 1) - 1;
    },
    methods: {
        setStepDone(done) {
            this.$store.dispatch('updateStepDone', { index: this.currentStepIndex, done });
        },

        setStepReady(ready) {
            this.$store.dispatch('updateStepReady', { index: this.currentStepIndex, ready });
        },

        async loadTemplateContent() {
            try {
                const generatedData = await axios.post(`${this.quickCreatorBaseUrl}/generate-content`, {
                        globalData: { ...this.globalData },
                        accountData: { ...this.accountData },
                        templateId: this.templateId,
                        empty: true
                    },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'x-mono-sid': this.sessionId
                        }
                    }
                );

                this.$store.dispatch('setAIData', generatedData.data.body);
            } catch(error) {
                console.log(error);
            }
        },

        findDifferentCountries() {
            const allCountries = countriesListJSON.countries;
            const gmbCountries = countriesJSONGBP.countries;
            const differentCountries = [];

            allCountries.forEach((allCountry) => {
                const foundInGmb = gmbCountries.some((gmbCountry) => allCountry.value === gmbCountry.value);
                if (!foundInGmb) {
                    differentCountries.push(allCountry);
                }
            });

            return differentCountries;
        },

        chooseFields(questionsReceived) {
            const toCamelCase = (str) => {
                return str.replace(/_([a-z])/g, (match, p1) => p1.toUpperCase());
            };
            if (questionsReceived && questionsReceived.length >= 0) {
                const camelCaseQuestions = questionsReceived.map(toCamelCase);
                const filteredArray = this.aiFields.filter(item => {
                    return camelCaseQuestions.includes(item.name);
                });
                return filteredArray;
            }
            return [];
        },

        countWords(string){
            return string.trim().replace(/[ ]{2,}/gi," ").split(" ").length;
        },

        uploadComplete(file) {
            const newData = {
                value: file.data.value
            };

            const regex = /(.*?_c\d+)(idx:\d+)/;
            const matches = file.index.match(regex);

            if (matches) {
                const fileIndex = matches[1];
                const indexImg = matches[2].split(":")[1];
                if (!this.imagesUploaded[fileIndex]) {
                    this.$set(this.imagesUploaded, fileIndex, {});
                }
                this.$set(this.imagesUploaded[fileIndex], indexImg, [newData]);
            } else {
                this.$set(this.imagesUploaded, file.index, [newData]);
            }
            this.$store.dispatch('setStoredImages',  this.imagesUploaded);
        },

        deleteImage(key, index = 0) {
            if (this.imagesUploaded[key] && this.imagesUploaded[key][index] !== undefined) {
                if (this.imagesUploaded[key][index].length > 0) {
                    this.imagesUploaded[key][index][0].value = null;
                }
                this.imagesUploaded[key][index].value = null;
            }
            this.$store.dispatch('setStoredImages',  this.imagesUploaded);
        },

        hasUpdloadedImage(key, index = 0) {
            if (this.imagesUploaded[key] && this.imagesUploaded[key][index] !== undefined && this.imagesUploaded[key][index].value !== null) {
                return true;
            }
            return false;
        }
    }
};