import axios from 'axios';
import { mapGetters } from 'vuex';
import { $ss } from "@/plugins/localstorage";

export default {
    data() {
        return {
            templates: [],
            filterData: [],
            filterType: 'STANDARD',
            previewUrl: '',
            currentTemplate: {},
            loadingTemplates: false
        };
    },
    computed: {
        ...mapGetters({
            sessionId: 'sessionId'
        }),
        filteredTemplates() {
            let filteredList = this.templates;
            if (this.filterData.length) {
                filteredList = filteredList.filter(template => {
                    if (!template.templateHeadings) {
                        return false;
                    }
                    const category = template.templateHeadings.map(heading => heading.translationKey);
                    const intersection = this.filterData.filter(x => category.includes(x));
                    return intersection.length > 0;
                });
            }
            if (this.filterType.length) {
                filteredList = filteredList.filter(template => this.filterType.includes(template.type.toUpperCase()));
            }
            return filteredList;
        }
    },
    async mounted() {
        await this.getTemplates();
    },
    methods: {
        previewTemplate(template) {
            this.previewUrl = template.templatePreviewUrl.replace(/^https:/, "");
            this.currentTemplate = template;

            this.$modal.show('previewOverlay');

            const switchHeaderAndContent = setInterval(() => {
                const header = document.getElementsByClassName('overlay-header');
                const content = document.getElementsByClassName('overlay-content');
                if (header.length > 0 && content.length > 0) {
                    if (this.$mq === 'mobile') {
                        content[0].after(header[0]);
                        clearInterval(switchHeaderAndContent);
                    }
                }
            }, 50);
        },
        async getTemplates() {
            this.loadingTemplates = true;
            const quickCreatorBaseUrl = process.env.VUE_APP_QC_BASE_URL;
            try {
                const response = await axios.get(`${quickCreatorBaseUrl}/templates`,
                    {
                    headers: {
                        'Content-Type': 'application/json',
                        'x-mono-sid': this.sessionId
                    }
                });
                this.templates = response.data;
                this.loadingTemplates = false;
            } catch (error) {
                // TODO
                console.log('GET TEMPLATES ERROR --- ', error);
                this.loadingTemplates = false;
                if (error.message === 'Network Error') {
                    // Handle the network error gracefully
                    console.error('Network Error:', error);
                    $ss.set('loginToken', '');
                    this.errorMessage = 'There was a network error. Please try again later.';
                } else {
                    console.error('GET TEMPLATES ERROR', error);
                    this.errorMessage = 'An error occurred while retrieving templates.';
                }
            }
        },
        setFilterData(filterData) {
            if (filterData === 'AI' || filterData === 'STANDARD') {
                this.filterType = filterData;
                return;
            }
            this.filterData = filterData;
        },
        clearTemplate() {
            this.$store.dispatch('setTemplateId', '');
        }
    }
};