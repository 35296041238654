<template>
	<div id="qcApp">
		<template>
			<header-el />
			<div v-show="!flowFinished">
				<router-view />
			</div>
			<footer-el />
		</template>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import HeaderEl from './components/Header.vue';
import FooterEl from './components/Footer.vue';
import { loadLanguage } from '@/utils/lang';

export default {
	name: 'app',
	components: { HeaderEl, FooterEl },
	computed: {
		...mapGetters({
			flowFinished: 'flowFinished',
		})
	},
    async mounted() {
		await loadLanguage('en');
    },
};
</script>

<style>
#app {
	height: 100%;
	overflow: hidden;
	font-family: "Roboto", sans-serif, sans-serif;
	color: #3e3e3e;
	-webkit-font-smoothing: antialiased;
}
</style>
