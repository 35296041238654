<template>
    <div class="templateFilter">
        <headline :icon="headlineIcon" :text="title" size-class="mono-size-default" @click.native="toggleVisible" />
        <input-checkbox-field
            v-for="(tag, i) in tags"
            v-show="visibleFilters"
            :key="i"
            :label="`general.themes.tags.${tag}`" :name="`filterTag_${tag}`"
            index="filterTag"
            @change="updateFilterData($event, tag)" />
    </div>
</template>

<script>
import { Headline, InputCheckboxField } from "@monosolutions/vue-components";

export default {
    name: 'template-filter',
    components: { InputCheckboxField, Headline },
    props: {
        tagTemplates: {
            type: Array,
            'default': () => {
                return [];
            }
        },
        hasToggle: {
            type: Boolean,
            'default': false,
        },
        title: {
            type: String,
            'default': ''
        }
    },
    data() {
        return {
            filterData: [],
            visibleFilters: true,
        };
    },
    computed: {
        tags() {
            const tagData = [];
            this.tagTemplates.forEach(template => {
                if (!template.templateHeadings) {
                    return;
                }
                template.templateHeadings.forEach(heading => {
                    const splitTag = heading.translationKey.replace(/\s/g, '');
                    if (!tagData.includes(splitTag)) {
                        tagData.push(splitTag);
                    }
                });
            });
            return tagData;
        },
        headlineIcon() {
            if (this.hasToggle) {
                return this.visibleFilters ? 'mf-angle-up-s' : 'mf-angle-down-s';
            }
            return '';
        }
    },
    watch: {
        '$mq'(to, from) {
            if (from !== to && to !== 'mobile' && !this.visibleFilters) {
                this.visibleFilters = true;
            }
        }
    },
    mounted() {
        this.visibleFilters = this.$mq !== 'mobile';
    },
    methods: {
        toggleVisible() {
            if (this.hasToggle) {
                this.visibleFilters = !this.visibleFilters;
            }
        },
        updateFilterData(e, tag) {
            if (e.data.checked) {
                this.filterData.push(tag);
            } else {
                this.filterData.splice(this.filterData.indexOf(tag), 1);
            }
            this.$emit('filterUpdated', this.filterData);
        }
    }

};
</script>
