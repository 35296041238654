import { mapGetters } from 'vuex';
import axios from 'axios';

export default {
    computed: {
        ...mapGetters({
            sessionId: 'sessionId',
            allStandardModules: 'modules',
            stepsData: 'stepsData',
            stepsDone: 'stepsDone',
            currentStepIndex: 'currentStepIndex',
            lastStep: 'lastStep',
            isAIFlow: 'isAIFlow',
            stepHistory: 'stepHistory',
            isManualInput: 'isManualInput',
            isAIAPIDown: 'isAIAPIDown'
        }),
        quickCreatorBaseUrl() {
            return process.env.VUE_APP_QC_BASE_URL;
        },
        allModules() {
            let modulesArr = [];
            let modules = [];

            if (this.allStandardModules.length > 0) {
                modules = JSON.parse(JSON.stringify(this.allStandardModules));
                modulesArr = this.allStandardModules;
            } else if (Object.keys(this.stepsData).length > 0) {
                modules = Object.keys(this.stepsData).map(key => this.stepsData[key]);
                modulesArr = Object.keys(this.stepsData).map(key => this.stepsData[key]);
                this.$router.push(`/step/${this.currentStepIndex + 1}`).catch(() => { });
            }

            return modules.map((_module, i) => {
                if (_module.aiFlow && this.isAIFlow) {
                    _module.nextStep = _module.aiFlow;
                    delete _module.aiFlow;
                }

                return {
                    ..._module,
                    step: i,
                    nextStepIndex: modulesArr.findIndex(m => {
                        return m.name === _module.nextStep;
                    })
                }
            });
        },
        currentStepIsDone() {
            return this.allModules && this.stepsDone[this.currentStepIndex];
        }
    },

    methods: {
        async nextStep(skip) {
            // If the current step is not done, don't move on
            // If the current module is lastStep in a sequence, don't move on
            if (!this.currentStepIsDone || this.allModules[this.currentStepIndex].nextStepIndex === -1) {
                return;
            }

            const nextStepIndex = this.allModules[this.currentStepIndex].nextStepIndex;

            if (this.isAIFlow && nextStepIndex === 5 && !this.isManualInput) {
                this.$store.dispatch('setNextButtonLoading', true);
                await this.apiHealthCheck();
                if (this.isAIAPIDown)
                    return;
            }

            // Add current step to history before leaving
            this.$store.dispatch('addStepHistory', this.currentStepIndex);

            if (skip && nextStepIndex === 1) {
                this.$store.dispatch('setClearGlobalData', true);
            }

            // Set new currentStepIndex
            this.$store.dispatch('setCurrentStepIndex', nextStepIndex);

            this.$store.dispatch('updateStepData', this.allModules);

            // Change page forward
            this.$router.push(`/step/${nextStepIndex + 1}`).catch(() => { });
        },

        prevStep() {
            if (!this.stepHistory.length) {
                return;
            }

            let prevIndex = this.allModules[this.stepHistory[this.stepHistory.length - 1]].nextStepIndex;
            if (this.allModules[this.currentStepIndex].nextStepIndex === 5) {
                prevIndex = prevIndex - 1;
            }
            // Update store - remove step from history and set the current step index
            this.$store.dispatch('setCurrentStepIndex', this.stepHistory[this.stepHistory.length - 1]);
            this.$store.dispatch('removeStepHistory', this.stepHistory[this.stepHistory.length - 1]);

            // Change page
            this.$router.push(`/step/${prevIndex}`).catch(() => { });
        },

        async apiHealthCheck() {
            try {
                const options = await axios.get(`${this.quickCreatorBaseUrl}/healthcheck`,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'x-mono-sid': this.sessionId
                        }
                    }
                );
                const status = options.status === 503 ? true : false;
                this.$store.dispatch('setIsAIAPIDown', status);
                this.$store.dispatch('setNextButtonLoading', false);
            } catch(error) {
                console.log(error);
            }
        },

        clearInputs() {
            const emptyGlobalData =  {
                companyName: '',
                email: '',
                phone: '',
                street: '',
                city: '',
                zip: '',
                state: '',
                country: '',
                orgNumber: '',
                startDate: '',
                descriptionShort: '', 
                descriptionLong: '', 
                mission: '', 
                logo: '',
                services: '',
                siteLanguage: '',
                businessType: ''
            };
            const emptyAccountData = {
                customerId: ''
            };
            this.$store.dispatch('setGlobalData', emptyGlobalData);
            this.$store.dispatch('setAccountData', emptyAccountData);
            this.$store.dispatch('setGMBData', []);
            this.$store.dispatch('setStoredLocation', []);
            this.$store.dispatch('setFlowFinished', false);
            this.$store.dispatch('setTemplateId', '');
            this.$store.dispatch('setAIData', '');
            this.$store.dispatch('setStoredImages', {});
            this.$store.dispatch('setIsAIFlow', false);
            this.$store.dispatch('clearStepHistory');
            this.$store.dispatch('setNoCreditsFound', false);
            this.$store.dispatch('setNextButtonLoading', false);
            window.localStorage.removeItem('uploadedImage');
        },
    }
};