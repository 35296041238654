import Vue from 'vue'
import Vuex from 'vuex'
import i18n from '@/utils/lang'
import App from './App.vue'
import router from '@/router'
import store from '@/store'
import '@monosolutions/monoiconfont'
import VModal from 'vue-js-modal'
import VueMq from 'vue-mq'
import VueCookies from 'vue-cookies'


import './scss/styles.scss'

Vue.config.productionTip = false

Vue.use(Vuex)
Vue.use(VModal)
Vue.use(VueCookies)

Vue.use(VueMq, {
  breakpoints: {
      mobile: 767,
      tablet: 1200,
      desktop: Infinity,
  }
});

new Vue({
  i18n,
  store,
  router,
  render: h => h(App),
}).$mount('#app')