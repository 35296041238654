<template>
    <div class="moduleWrapper">
        <div class="contents templateSelection">
            <div class="fixedWrapper">
                <headline text="quickCreator.templateSelection.headline" class="mds-heading-three" :size-class="headlineSizeClass" />
                <preamble :text="isAIEnabled ? 'quickCreator.templateSelectionNew.preamble' : 'quickCreator.templateSelectionNew.noAIPreamble'" class="preamble" :size-class="preambleSizeClass" />
            </div>
            <div class="themesViewNew">
                <div class="themesContent" :class="{ loading: loadingTemplates }">
                    <div class="filterWrapper">
                        <template-filter-new :tag-templates="templates" :has-toggle="isMobile" title="quickCreator.templateSelection.filterTitle" @filterUpdated="setFilterData" />
                    </div>
                    <div class="templateChooserWrapper">
                        <div v-if="loading" class="loadingWrap">
                            <div class="loading" />
                        </div>
                        <template-chooser 
                            :templates="filteredTemplates"
                            :selected-template-id="selectedTemplateId"
                            @templatePreview="openPreview"
                            @templateSelect="applyTemplate" />
                    </div>
                    <preview-overlay :show-combined-viewports="false" :url="previewUrl" name="previewOverlay" @applyTemplate="applyTemplate" />
                </div>
            </div>
            <mv-icon-sprite />
        </div>
    </div>
</template>

<script>
import { Headline, Preamble, MvIconSprite} from "@monosolutions/vue-components";
import { mapGetters } from 'vuex';
import QCModuleMixin from '../mixins/QCModuleMixin';
import TemplateSelectMixin from '../mixins/TemplateSelectMixin';
import TemplateFilterNew from '../components/TemplateFilterNew.vue';
import TemplateChooser from '../components/TemplateChooser.vue';
import PreviewOverlay from '../components/PreviewOverlay.vue';

export default {
    name: 'qc-template-selection-new',
    components: { Headline, Preamble, TemplateFilterNew, TemplateChooser, PreviewOverlay, MvIconSprite },
    mixins: [ TemplateSelectMixin, QCModuleMixin ],
    props: {
        thumbPath: {
            type: String,
            'default': 'assets/themes/'
        }
    },
    data() {
        return {
            loading: false,
            selectedTemplateId: 0
        }
    },
    mounted() {
        this.selectedTemplateId = Number(this.templateId);
        if (this.selectedTemplateId) {
            this.setStepReady(true);
            this.setStepDone(true);
        }
    },
    computed: {
        ...mapGetters({
            templateId: 'templateId',
            isAIFlow: 'isAIFlow',
            isAIEnabled: 'isAIEnabled'
        }),
        isMobile() {
            return this.$mq === 'mobile';
        },
        headlineSizeClass() {
            return this.isMobile ? 'mono-size-big' : 'mono-size-huge';
        },
        preambleSizeClass() {
            return this.isMobile ? 'mono-size-tiny' : 'mono-size-normal';
        },
    },
    watch: {
        templateId(value) {
            this.$store.dispatch('setTemplateId', value);
            if (this.templateId === '') {
                this.selectedTemplateId = 0;
                this.$store.dispatch('setTemplateId', '');
                this.setStepDone(false);
                this.setStepReady(false);
            }
        }
    },
    methods: {
        async applyTemplate(template = this.currentTemplate) {
            this.loading = true;

            this.selectedTemplateId = template.id;
            this.$store.dispatch('setTemplateId', this.selectedTemplateId);
            try {
                this.loading = false;
                this.$modal.hide('previewOverlay');

                this.setStepReady(true);
                this.setStepDone(true);
            } catch (error) {
                this.setStepDone(false);
            }
        },
        openPreview(template) {
            this.previewTemplate(template);
        }
    }
};
</script>
