<template>
    <div class="moduleWrapper">
        <div class="sideimage">
            <img :src="'../images/' + sidebarMoreInfoImg" class="illustration">
        </div>
        <div class="contents moreInformation">
            <div class="fixedWrapper">
                <headline text="quickCreator.moreInformation.headline" class="mds-heading-three" :size-class="headlineSizeClass" />
                <preamble text="quickCreator.moreInformationNew.preamble" class="preamble" :size-class="preambleSizeClass" />
            </div>

            <div class="fixedWrapper inputs">
                <fields-more-information />

                <div v-if="errorMessageModal.show" class="modal-overlay">
                    <mv-modal
                        :title="errorMessageModal.title"
                        :description="errorMessageModal.description"
                        size="medium"
                        :firstButton="false"
                        :secondButton="false">
                            <div slot="mv-modal-content" class="greyWrapper">
                                <div class="wrapper">
                                    <span class="text">{{ $t(errorMessageModal.descriptionLong) }}</span>
                                    <div class="buttonWrapper">
                                        <mv-button class="borderless buttonOne" :text="errorMessageModal.button1" :isLoading="manualModeLoading" size="large" @click="buttonClick(1)" />
                                        <mv-button class="borderless" :text="errorMessageModal.button2" size="large" @click="buttonClick(2)" />
                                    </div>
                                </div>
                            </div>
                    </mv-modal>
                </div>
            </div>
            <mv-icon-sprite />
        </div>
    </div>
</template>

<script>
import QCModuleMixin from '../mixins/QCModuleMixin';
import QCNavigationMixin from '../mixins/QCNavigationMixin';
import FieldsMoreInformation from '../components/FieldsMoreInformation.vue';
import { Headline, Preamble, MvIconSprite, MvModal, MvButton } from "@monosolutions/vue-components";
import { mapGetters } from 'vuex';
import axios from 'axios';

export default {
    name: 'qc-more-information-new',
    components: { Headline, Preamble, MvIconSprite, MvModal, MvButton, FieldsMoreInformation },
    mixins: [ QCModuleMixin, QCNavigationMixin ],
    data() {
        return {
            sidebarMoreInfoImg: '1x-Magicianshat.png',
            errorMessageModal: {
                show: false,
                title: 'quickCreator.moreInformationNew.errorMessageModal.title',
                description: 'quickCreator.moreInformationNew.errorMessageModal.description',
                descriptionLong: 'quickCreator.moreInformationNew.errorMessageModal.descriptionLong',
                button1: 'quickCreator.moreInformationNew.errorMessageModal.button1',
                button2: 'quickCreator.moreInformationNew.errorMessageModal.button2',
            },
            manualModeLoading: false,
            apiFailsCounter: 0
        };
    },
    computed: {
        ...mapGetters({
            globalData: 'globalData',
            accountData: 'accountData',
            sessionId: 'sessionId',
            isAIAPIDown: 'isAIAPIDown',
            isManualInput: 'isManualInput',
            templateId: 'templateId',
            stepHistory: 'stepHistory'
        }),
        isMobile() {
            return this.$mq === 'mobile';
        },
        headlineSizeClass() {
            return this.isMobile ? 'mono-size-big' : 'mono-size-huge';
        },
        preambleSizeClass() {
            return this.isMobile ? 'mono-size-tiny' : 'mono-size-normal';
        },
        quickCreatorBaseUrl() {
            return process.env.VUE_APP_QC_BASE_URL;
        },
        aiAPIDown() {
            return this.isAIAPIDown;
        }
    },
    watch: {
        aiAPIDown(value) {
            if (value) {
                this.errorMessageModal.show = value;
                document.body.classList.add('disable-scroll');
            }
        }
    },
    async mounted() {
        this.$store.dispatch('setAIData', '');
        this.$store.dispatch('setNextButtonLoading', false);
    },
    destroyed() {
        if (this.stepHistory.length > 3) {
            this.loadAIGeneratedContent();
        }
    },  
    methods: {
        async buttonClick(decision) {
            if (decision === 1) {
                this.manualModeLoading = true;
                this.$store.dispatch('setIsManualInput', true);
                await this.loadAIGeneratedContent();
                document.body.classList.remove('disable-scroll');
                this.nextStep(false);
            }
            else if (decision === 2) {
                this.$store.dispatch('setIsAIFlow', false);
                document.body.classList.remove('disable-scroll');
                this.$router.push('/loading').catch(()=>{});
            }
        },

        async loadAIGeneratedContent() {
            if (this.isManualInput && !this.manualModeLoading) {
                return;
            }
            try {
                const generatedData = await axios.post(`${this.quickCreatorBaseUrl}/generate-content`, {
                        globalData: { ...this.globalData },
                        accountData: { ...this.accountData },
                        templateId: this.templateId,
                        empty: this.isManualInput
                    },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'x-mono-sid': this.sessionId
                        }
                    }
                );
                this.$store.dispatch('setAIData', generatedData.data.body);
                this.manualModeLoading = false;
            } catch(error) {
                if (error.response.status === 429) {
                    this.$store.dispatch('setNoCreditsFound', true);
                    return;
                }
                if (this.apiFailsCounter < 3) {
                    this.loadAIGeneratedContent();
                    this.apiFailsCounter++;
                } else {
                    this.$store.dispatch('setIsAIAPIDown', true);
                }
                console.log(error);
            }
        }
    }
};
</script>
