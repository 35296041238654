class FileExtensions  {
    constructor() {
        this.bitmapExtensions = [
            'jpg',
            'jpeg',
            'png',
            'gif',
            'tiff',
            'webp',
            'bmp'
        ];

        this.vectorExtensions = [
            'svg'
        ];

        this.documentExtensions = [
            'xls',
            'xlsx',
            'doc',
            'docx',
            'pdf',
            'ppt',
            'pptx'
        ];

        this.otherExtensions = [
            'ico'
        ];
    }

    getAsRegEx(fileType = [ 'bitmap' ], skipExtensions = []) {
        return new RegExp(`\\.(${this.getAsString(fileType, skipExtensions, '|')})$`, 'i');
    }

    getAsString(fileType = [ 'bitmap' ], skipExtensions = [], delimiter = ',') {
        const extensions = this._getFileExtensions(fileType);

        return extensions
            .filter(extension => !skipExtensions.includes(extension))
            .map(extension => `${delimiter === ',' ? '.' : ''}${extension}`)
            .join(delimiter);
    }

    getAsArray(fileType = 'bitmap') {
        return this[fileType];
    }

    // Private functions
    _getFileExtensions(fileType = []) {
        const extensions = [];
        fileType.forEach(type => extensions.push(...this[`${type}Extensions`]));
        return extensions;
    }
}

export default FileExtensions;