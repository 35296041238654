<template>
    <ul :class="{ hasIcons }" class="menupanel firstLevel">
        <p class="mainRouteName mds-capital-small">
            {{ $t(sidenavTitle) }}
        </p>
        <li v-for="(route, key) in routes"
                :key="key"
                :class="{ [routesColor]: true , active: isActiveRoute(route.name) }"
                class="route mainroute"
            >
                <div class="title" :class="{ noIcons: !hasIcons }" @click="updateActiveKey(route.name)">
                    <mv-icon v-if="hasIcons" class="routeIcon" :type="route.icon" />
                    <span class="mds-heading-six">{{ $t(route.name) }}</span>
                    <div v-if="route.isConfirmed" :class="[ 'approved', tagColor ]">
                        <span class="mds-heading-six">{{ $t(tagText) }}</span>
                    </div>
                </div>
            </li>
        <mv-icon-sprite />
    </ul>
</template>

<script>
import { MvIcon, MvIconSprite } from "@monosolutions/vue-components";

export default {
    name: 'qc-sidenav',
    components: { MvIcon, MvIconSprite },
    props: {
        sidenavTitle: {
            type: String,
            'default': ''
        },
        routes: {
            type: Object,
            'default': () => { }
        },
        activeKey: {
            type: String,
            'default': ''
        },
        routesColor: {
            type: String,
            'default': 'white'
        },
        tagText: {
            type: String,
            'default': 'Approved'
        },
        tagColor: {
            type: String,
            'default': 'blue'
        },
    },
    computed: {
        hasIcons() {
            return true;
        }
    },
    mounted() {
    },
    methods: {
        isActiveRoute(name) {
            return this.activeKey === name.toLowerCase();
        },
        updateActiveKey(name) {
            this.$emit('updateActiveKey', name.toLowerCase());
        }
    }
};
</script>
